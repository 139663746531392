import { MachineVersionDetailsAPI } from "../adapters/MachineVersionDetailsAdapter/MachineVersionDetailsAdapter"
export const getMachineVersionDetails = async (machineUDI) => {
    try {
        return new Promise(async (resolve, reject) => {
            await MachineVersionDetailsAPI(machineUDI)
                .then((res) => {
                    if(res && res.data && res.status===200){
                        resolve(res.data.data);
                        console.log(res.data.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                })
        })
    } catch (error) {
        console.log(error);
    }
};