import axios from "axios";
import "../../common/axiosInterceptor/axios-interceptor";

export const getVentilatorAlarmHistoryAPI = (data) => {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/ventilatorEventsList`,
        data: data,
        authRequired: true
    })
}