import axios from "axios";
import "../../common/axiosInterceptor/axios-interceptor";

export const MachineVersionDetailsAPI = async (machineUDI) => {
    const data = {
        "machineUDI": machineUDI
    }
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/getVersionsLog`,
        data: data,
        authRequired: true
    })
}