const axios = require('axios');
const { LOGIN_CONST } = require('../constant/page-constants');
const { getCookieCustom } = require('../utils/StorageUItils');

axios.interceptors.request.use((config) => {
    if (config.authRequired) {
        if (getCookieCustom(LOGIN_CONST.ACCESS_TOKEN) && getCookieCustom(LOGIN_CONST.REFRESH_TOKEN)) {
            config.headers['Authorization'] = `${getCookieCustom(LOGIN_CONST.ACCESS_TOKEN)}`;
        } else {
            window.location = "/";
        }
    }

    return config;
}, (error) => {

    console.log("error in interceptor request :: " + error);
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {

    if (error && error.response && (401 === error.response.status)) {
        window.location = "/";
    } else {
        return Promise.reject(error);
    }
    return Promise.reject(error.message);
});