import React from 'react'
import "./OtaDashboard.css"
import SideBar from "../../components/SideBar/SideBar"
import WebHeader from "../../components/WebHeader/WebHeader"
import { Outlet } from 'react-router-dom'
import UpdateSystemFullScreenModal from '../../components/UpdateSystemFullScreenModal/UpdateSystemFullScreenModal'
import { connect } from 'react-redux'
import { setSystemInactiveFullScreenModal, setSystemUpdateFullScreenModal, setUploadSystemVersionFullScreenModal } from '../../modules/actions/action'
import InactivateSystemVersionFullScreenModal from '../../components/InactivateSystemVersionFullScreenModal/InactivateSystemVersionFullScreenModal'
import UploadSystemVersionFullScreenModal from '../../components/UploadSystemVersionFullScreenModal/UploadSystemVersionFullScreenModal'

const OtaDashboard = (props) => {
	return (
		<div className="ota-management-main-container">
			{props.showUpdateSystemModal && <div className='full-screen-system-update-modal'>
				<UpdateSystemFullScreenModal />
			</div>}
			{props.showInactiveSystemModal && <div className='full-screen-system-update-modal'>
				<InactivateSystemVersionFullScreenModal />
			</div>}

			{props.showUploadSystemModal && <div className='full-screen-system-update-modal'>
				<UploadSystemVersionFullScreenModal/>	
			</div>}

			<div className='ota-dashboard-screen'>
				<div className='web-header'>
					<WebHeader />
				</div>
				<div className='ots-dashboard'>
					<div className='ots-sidebar'>
						<SideBar />
					</div>
					<div className='ots-container'>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	)
};

const mapStateToProps = state => {
	return {
		showUpdateSystemModal: state.systemUpdateFullScreenModal.showSystemUpdateModal,
		showInactiveSystemModal: state.systemInactiveFullScreenModal.showSystemInactiveModal,
		showUploadSystemModal: state.uploadSystemVersionFullScreenModal.showUploadSystemVersionModal,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		openSystemUpdateModal: () => { dispatch(setSystemUpdateFullScreenModal(true)) },
		closeSystemUpdateModal: () => { dispatch(setSystemUpdateFullScreenModal(false)) },
		openSystemInactiveModal: () => { dispatch(setSystemInactiveFullScreenModal(true)) },
		closeSystemInactiveModal: () => { dispatch(setSystemInactiveFullScreenModal(false)) },
		openUploadSystemVersionModal: () => { dispatch(setUploadSystemVersionFullScreenModal(true)) },
        closeUploadSystemVersionModal: () => { dispatch(setUploadSystemVersionFullScreenModal(false)) },
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(OtaDashboard);