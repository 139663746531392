import { Types } from './actionTypes';

export const setUserDetails = (data) => {
    return {
        type: Types.LOGIN_SUCCESS,
        payload: (data)
    }
};

export const setActiveIcon = (activeIcon) => {
    // console.log(activeIcon);
    return {
        type: Types.CHANGE_SIDEBAR_ACTIVE_ICON,
        payload: (activeIcon)
    }
};

export const setActiveSystemUpdateIcon = (systemUpdateIcon) => {
    // console.log(systemUpdateIcon);
    return {
        type: Types.TOGGLE_SYSTEM_UPDATE_VERSION_BUTTON,
        payload: (systemUpdateIcon)
    }
};

export const setSystemUpdateFullScreenModal = (setModal) => {
    // console.log(setModal);
    return {
        type: Types.TOGGLE_SYSTEM_UPDATE_FULL_SCREEN_MODAL,
        payload: (setModal)
    }
};

export const setNotificationVisibility = (setNotification) => {
    // console.log("NOTIFICATION PAYLOAD",setNotification);
    return {
        type: Types.CHANGE_NOTIFICATION_VISIBILITY,
        payload: (setNotification)
    };
};

export const setSystemInactiveFullScreenModal = (setModal) => {
    // console.log(setModal);
    return {
        type: Types.TOGGLE_SYSTEM_INACTIVE_FULL_SCREEN_MODAL,
        payload: (setModal)
    }
};

export const setInactiveSystemVersionButton = (systemInactiveIcon) => {
    // console.log(systemInactiveIcon);
    return {
        type: Types.TOGGLE_SYSTEM_INACTIVE_VERSION_BUTTON,
        payload: (systemInactiveIcon)
    }

};

export const setUploadSystemVersionFullScreenModal = (setModal) => {
    // console.log("upload System Version modal",setModal);
    return {
        type: Types.TOGGLE_UPLOAD_SYSTEM_VERSION_FULL_SCREEN_MODAL,
        payload: (setModal)
    }
};

export const selectMachineToUpdateVersionButton = (machineToUpdateVersion) => {
    console.log("Selected Machine to update",machineToUpdateVersion);
    return {
        type: Types.SELECT_MACHINE_TO_UPDATE_VERSION,
        payload: (machineToUpdateVersion)
    }
};

export const showUpdateMachineVersionNotification = (notification) =>{ 
    console.log("NOtification close request", notification);
    return {
        type: Types.SHOW_UPDATE_MACHINE_VERSION_NOTIFICATION,
        payload: (notification)
    }
};

export const showSystemVersionUploadNotification = (notification) =>{
    console.log("System Version upload notification", notification);
    return {
        type: Types.SHOW_SYSTEM_VERSION_UPLOAD_NOTIFICATION,
        payload: (notification)

    }
};