export const CONTAINER_CONST = {
    OTA_DETAILS_ACTIVE: "OTA_DETAILS_ACTIVE",
    OTA_UPLOAD_ACTIVE: "OTA_UPLOAD_ACTIVE",
    IP_LOG_UPLOAD_ACTIVE: "IP_LOG_OTA_UPLOAD_ACTIVE",
    REQUEST_CALLBACK: "REQUEST_CALLBACK",
    DEMO_MACHINE: "DEMO_MACHINE",
};

export const PAGE_CONST = {
    HOME: "HOME",
    MACHINE_VERSION_UPDATE_DETAILS: "MACHINE_VERSION_UPDATE_DETAILS",
    MACHINE_DETAILS: "MACHINE_DETAILS",
    IP_LOG: "IP_LOG",
    LOGIN: "LOGIN"
};

export const BUTTON_CONST = {
    UPDATE_SYSTEM_BUTTON: "UPDATE_SYSTEM_BUTTON",
}

export const TIMELINE_CONST = {
    DATE_TIME_FORMAT: "DD-MM-YYYY HH:mm",
    DATE_FORMAT: "DD-MM-YYYY",
    TIME_FORMAT: "HH:mm:ss"
};