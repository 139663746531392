import React from 'react'
import './MessageNotification.css'
import cross_svg from '../../common/icons/cross.svg'
import success_svg from '../../common/icons/green_success_dot.svg'
import { setNotificationVisibility, showSystemVersionUploadNotification, showUpdateMachineVersionNotification } from '../../modules/actions/action';
import { connect } from 'react-redux';

const MessageNotification = (props) => {
  const handleCrossIcon = () => {
    props.closeNotification();
    props.closeMachineUpdateNotification();
    props.closeSystemVersionUploadNotification();
  }

  setTimeout(() => {
    props.closeNotification();
    props.closeMachineUpdateNotification();
    props.closeSystemVersionUploadNotification();
  }, 8000);

  return (
    <div className='message_notificatin_container'>
      <img src={success_svg} />
      {/* <p><span>System Version 16.7.5</span> shared with <span>V730i8765CVGHJN</span>Successfully!</p> */}
      {!props.systemVersionUpload && <p><span>System Version {props.systemVersion}</span>shared with machine <span>Successfully!</span></p>}
      {props.systemVersionUpload  && <p><span>Software Version</span> and <span>Os Version</span> uploaded successfully!</p>}
      <img src={cross_svg} onClick={handleCrossIcon} />
    </div>
  )
};


const mapStateToProps = state => {
  return {
    showModal: state.systemUpdateFullScreenModal.showSystemUpdateModal,
    showNotification: state.showNotification.showNotificatin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openNotification: () => { dispatch(setNotificationVisibility(true)) },
    closeNotification: () => { dispatch(setNotificationVisibility(false)) },
    closeMachineUpdateNotification: () => {dispatch((showUpdateMachineVersionNotification(false)))},
    closeSystemVersionUploadNotification: ()=> {dispatch(showSystemVersionUploadNotification(false))}
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageNotification);