import { MachineVersionUpdateAPI } from "../adapters/MachineVersionUpdateAdapter/MachineVersionUpdateAdapter";
export const postMachineVersionUpdate = async (machineData) => {
    try {
        console.log("Machine Data is comming: ",machineData);
        return new Promise(async (resolve, reject) => {
            await MachineVersionUpdateAPI(machineData)
                .then((res) => {
                    if(res){
                        resolve(res);
                        console.log("MACHINE API RESPONSE: ", res);
                    }
                })
                .catch((err) => {
                    console.log("MACHINE API ERROR",err.response.data.data);
                    reject(err);
                })
        })
    } catch (error) {
        console.log(error);
    }
};