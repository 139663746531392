import { PAGE_CONST } from "../constant/common-constants";

export const redirect = (navigate, page, data) => {
    switch (page) {
        case PAGE_CONST.HOME:
            navigate(`/ota-details/machine-details`);
            break;
        case PAGE_CONST.MACHINE_VERSION_UPDATE_DETAILS:
            navigate(`/ota-details/machine-details/${data.mahcineUDI}`);
            break;
        case PAGE_CONST.MACHINE_DETAILS:
            navigate(`/ota-details/machine-details/${data.mahcineUDI}`);
            break;
        case PAGE_CONST.IP_LOG:
            navigate(`/ip-log`);
            break;
        default:
            navigate(`/`);
            break;
    }
}