import React, { useEffect, useState, useRef } from 'react'
import './Iplog.css'
import moment from 'moment';
import ReactTable from '../../components/ReactTable/ReactTable';
import { TIMELINE_CONST } from '../../common/constant/common-constants';
import { getIpLogs } from '../../helper/IpLogHelper';
import { ROLE } from '../../enum/role';
import { connect } from 'react-redux';

const IpLog = (props) => {
  const [dataContainer, setDataContainer] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef(null);

  useEffect(() => {
    handleGetIpLog();
    // Event listener for Command + K
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleGetIpLog = () => {
    getIpLogs()
      .then((res) => {
        let data = res.data;
        setDataContainer(data);
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getTableColumn = () => {
    const columns = [
      {
        title: 'MACHINE SERIAL NO.',
        dataIndex: 'machineUDI',
        key: 'machineUDI',
        className: 'machine_serial_number_id_class header_column text-align-left',
        render: (value, row) => (
          <>
            {row.machineUDI.split('-').filter(x=>x.includes('1G'))}
          </>
        )
      },
      {
        title: 'IP Address',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        className: 'current_version_class header_column',
        children: [
          {
            title: 'WIFI',
            dataIndex: 'ipAddress',
            key: 'wlan0',
            className: 'current_version_class header_column ',
            id: 'wlan_id',
            children: [
              {
                title: 'IPV4',
                dataIndex: 'ipAddress',
                key: 'ipv4',
                className: 'current_version_class header_column',
                id: 'ipv4_id',    
                render: (value, row) => (
                  <>
                    {row.ipAddress.wlan0.ipV4}
                  </>)
              },
              {
                title: 'IPV6',
                dataIndex: 'ipAddress',
                key: 'ipv6',
                className: 'current_version_class header_column',
                id: 'ipv6_id',    
                render: (value, row) => (
                  <>
                    {row.ipAddress.wlan0.ipV6}
                  </>)
              }
            ],
          },
          {
            title: 'LTE',
            dataIndex: 'ipAddress',
            key: 'wwan0',
            className: 'current_version_class header_column ',
            id: 'wwan0_id',
            children: [
              {
                title: 'IPV4',
                dataIndex: 'ipAddress',
                key: 'ipv4',
                className: 'current_version_class header_column',
                id: 'ipv4_id',    
                render: (value, row) => (
                  <>
                    {row.ipAddress.wwan0.ipV4}
                  </>)
              },
              {
                title: 'IPV6',
                dataIndex: 'ipAddress',
                key: 'ipv6',
                className: 'current_version_class header_column',
                id: 'ipv6_id',    
                render: (value, row) => (
                  <>
                    {row.ipAddress.wwan0.ipV6}
                  </>)
              }
            ],
          }
        ],

        // render: (value, row) => (
        //   <>
        //     {row.ipAddress.eth1.ipV4}
        //     {row.ipAddress.eth1.ipV6}
        //     {row.ipAddress.wlan0.ipV4}
        //     {row.ipAddress.wlan0.ipV6}
        //     {row.ipAddress.wwan0.ipV4}
        //     {row.ipAddress.wwan0.ipV6}
        //   </>
        // )
      },
      {
        title: 'UPDATE DATE',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{moment(value).format(TIMELINE_CONST.DATE_FORMAT)}</span>
          }
        }
      },
      {
        title: 'UPDATE TIME',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        className: 'update_time_class header_column',
        render: (value, row) => {
          if (!value) {
            return <span>-</span>
          } else {
            return <span>{moment(value).format(TIMELINE_CONST.TIME_FORMAT)}</span>
          }
        }
      }
    ];

    return columns;
  };

  const getTableData = () => {
    let filteredData = [];

    if (Array.isArray(dataContainer)) {
      filteredData = dataContainer.filter(item =>
        item.machineUDI.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filteredData;
  };

  if (!(props.userRole === ROLE['ADMIN'] || props.userRole === ROLE['PRODUCTION'])) {
		return (
			  <div>
            <h1>Unauthorized Access</h1>
            <p>You do not have the necessary permissions to access this page.</p>
        </div>
		)
	}

  return (
    <div style={{position:"relative"}}>
      <div className='ots-details-container'>
        <div className='system-detail-sub-header-container'>
          <div className='system-detail-sub-header'>
            <div className='system-detail-sub-header-heading'>
              Machine IP Logs
            </div>
            <input
              type='text'
              placeholder='Quick Search          ⌘ K'
              value={searchQuery}
              onChange={handleSearchChange}
              className='search-input'
              ref={searchInputRef}
            />
          </div>
        </div>
        <ReactTable columns={getTableColumn()} data={getTableData()} rowKey="mahcineUDI" className="react-table" />
      </div>
    </div>
  )
};


const mapStateToProps = (state) => {
  return {
      userRole: state.userDetails.userRole
  }
};


const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IpLog);
