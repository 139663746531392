import { UpdateMachineServiceRequestApi } from "../adapters/RequestCallbackAdapter/UpdateMachineServiceRequest";
import { RequestCallback } from "../adapters/RequestCallbackAdapter/RequestCallback";
import { AssignHospitalApi } from "../adapters/RequestCallbackAdapter/AssignHospital";
export const getRequestCallback = () => {
    var promise = new Promise(function (resolve, reject) {
        RequestCallback().then((res) => {
            if (res && res.data && res.status === 200) {
                let response = res.data;
                // console.log("Machine Details Helper Call",response);
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in order details Api :: " + err);
        });
    })

    return promise;
};

export const UpdateMachineServiceRequest = (data) => {
    var promise = new Promise(function (resolve, reject) {
        UpdateMachineServiceRequestApi(data).then((res) => {
            if (res && res.data && res.status === 200) {
                let response = res.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in order details Api :: " + err);
        });
    })

    return promise;
};

export const AssignHospital = (data) => {
    var promise = new Promise(function (resolve, reject) {
        AssignHospitalApi(data).then((res) => {
            if (res && res.data && res.status === 200) {
                let response = res.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in order details Api :: " + err);
        });
    })

    return promise;
};