// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const selectMachineToUpdateVersionReducer = (state = initialState.selectMachineToUpdateVersionReducer, action) => {
    switch (action.type) {
        case Types.SELECT_MACHINE_TO_UPDATE_VERSION:
            return { ...state, selectedMachineToUpdateVersion: action.payload};
        default:
            return state;
    };
};