export const MODE = {
    1001: {
        modeName: 'PC',
        key: 'pc_ac',
        modeType: 'non-invasive',
        TITLE_START: 'PC',
    },
    1002: {
        modeName: 'VC',
        key: 'vc_ac',
        modeType: 'non-invasive',
        TITLE_START: 'VC',
    },
    1003: {
        modeName: 'PRVC',
        key: 'prvc_ac',
        modeType: 'non-invasive',
        TITLE_START: 'PRVC',
    },
    1004: {
        modeName: 'SIMV(PC)+PS',
        key: 'pc_simv',
        modeType: 'non-invasive',
        TITLE_START: 'SIMV(PC)+PS',
    },
    1005: {
        modeName: 'SIMV(VC)+PS',
        key: 'vc_simv',
        modeType: 'non-invasive',
        TITLE_START: 'SIMV(VC)+PS',
    },
    1006: {
        modeName: 'SIMV(PRVC)+PS',
        key: 'prvc_simv',
        modeType: 'non-invasive',
        TITLE_START: 'SIMV(PRVC)+PS',
    },
    1007: {
        modeName: 'APRV',
        key: 'aprv',
        modeType: 'non-invasive',
        TITLE_START: 'APRV',
    },
    1008: {
        modeName: 'PS/CPAP',
        key: 'psv',
        modeType: 'non-invasive',
        TITLE_START: 'PS/CPAP',
    },
    1009: {
        modeName: 'VS-VC',
        key: 'vs_vc',
        modeType: 'non-invasive',
        TITLE_START: 'VS-VC',
    },
    1010: {
        modeName: 'VS-PRVC',
        key: 'vs_prvc',
        modeType: 'non-invasive',
        TITLE_START: 'VS-PRVC',
    },
    1011: {
        modeName: 'NIV-PS',
        key: 'cpap_ps',
        modeType: 'invasive',
        TITLE_START: 'NIV-PS',
    },
    1012: {
        modeName: 'HFOT',
        key: 'hfot',
        modeType: 'invasive',
        TITLE_START: 'HFOT',
    },
    1013: {
        modeName: 'DualPAP',
        key: 'niv_pc',
        modeType: 'invasive',
        TITLE_START: 'DualPAP',
    }
}

export const TEMP_MODE = {
    pc_ac: {
        mode: 'PC',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012,104038],
        setParams: [100501,100502,100503,100504,100506,100507,100508]
    },
    vc_ac: {
        mode: 'VC',
        monitorParams: [104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100503,100511,100513,100506,100512,100508]
    },
    prvc_ac: {
        mode: 'PRVC',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100503,100511,100506,100507,100508]
    },
    pc_simv: {
        mode: 'SIMV(PC)+PS',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100503,100504,100506,100507,100508,100509,100510,100514]
    },
    vc_simv: {
        mode: 'SIMV(VC)+PS',
        monitorParams: [104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100503,100511,100513,100506,100512,100507,100508,100509,100510,100514]
    },
    prvc_simv: {
        mode: 'SIMV(PRVC)+PS',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012,104038],
        setParams: [100501,100502,100503,100511,100506,100507,100508,100509,100510,100514]
    },
    aprv: {
        mode: 'APRV',
        monitorParams: [104034,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100516,100517,100518,100519,100520,100521,100510,100507,100508]
    },
    psv: {
        mode: 'PS/CPAP',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100509,100510,100507,100508,100515,100503,100504,100506]
    },
    vs_vc: {
        mode: 'VS-VC',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100511,100510,100507,100508,100515,100503,100506,100513,100512]
    },
    vs_prvc: {
        mode: 'VS-PRVC',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100502,100511,100510,100507,100508,100515,100503,100506]
    },
    cpap_ps: {
        mode: 'NIV-PS',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100524,100509,100525,100526,100508,100515,100503,100504,100506]
    },
    hfot: {
        mode: 'HFOT',
        monitorParams: [104001,104023,104021,104024],
        setParams: [100501,100522]
    },
    niv_pc: {
        mode: 'DualPAP',
        monitorParams: [104034,104035,104032,104033,104036,104037,104016,104019,104012],
        setParams: [100501,100527,100503,100528,100529,100507,100508,100525]
    }
}