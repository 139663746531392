import React from 'react';
import './UploadingProgress.css';
import cross_svg from '../../common/icons/cross.svg';

const UploadingProgress = (props) => {

    return (
        <div className='progress_bar_container'>
            <div>
                <p className='version_details' >{props.UploadingProgress ? 'Error in Uploading File' : 'Software Version 12.8.8.9'}</p>
                <div className='progress_bar'>
                    <span className='progress'>
                        <span style={{ width: (`${props.countProgress}%`) }} className={`${!props.countProgress ? 'error': ''}`}></span>
                    </span>
                </div>
                <div className='progress_states'>
                    <span>{props.uploadMB}MB of {props.totalMB}MB</span>
                    <span className={`${props.uploadError ? 'try_again_error': ''}`} onClick={props.handleTryAgain}>{!props.countProgress ? 'Try Again' : `Uploading ${props.countProgress}%`}</span>
                </div>
            </div> 
            <img src={cross_svg} onClick={props.handleVersionProgressCrossIcon} />
        </div>
    )
}

export default UploadingProgress;