import React from 'react';
import './InactivateSystemVersionFullScreenModal.css';
import { connect } from 'react-redux';
import { setNotificationVisibility, setSystemInactiveFullScreenModal } from '../../modules/actions/action';

const InactivateSystemVersionFullScreenModal = (props) => {
  const handleModalVisibility = (e) => {
    e.preventDefault();
    console.log("Before ", props.showModal);
    props.closeModal();
    console.log("Inactive Modal",props.showModal);
  };
  return (
    <div className='full_screen_modal_container'>
      <div className='full_screen_modal'>
        <form>
          <p>Are you sure you want to make the System Version 16.1.2 Inactive?</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus turpis at leo ut.</p>
          <div className='system_update_button'>
            <button type="cancel" onClick={(e) => handleModalVisibility(e)}>Cancel</button>
            <button type="confirm" onClick={(e) => handleModalVisibility(e)}>Confirm</button>
          </div>
        </form>
      </div>
    </div>
  )
};


const mapStateToProps = state => {
  return {
      showModal: state.systemInactiveFullScreenModal.showSystemInactiveModal,
      showNotification: state.showNotification.showNotificatin
  };
};

const mapDispatchToProps = dispatch => {
  return {
      openModal: () => { dispatch(setSystemInactiveFullScreenModal(true)) },
      closeModal: () => { dispatch(setSystemInactiveFullScreenModal(false)) },
      openNotification: () => { dispatch(setNotificationVisibility(true)) },
      closeNotification: () => { dispatch(setNotificationVisibility(false)) },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InactivateSystemVersionFullScreenModal);