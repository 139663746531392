import React, { useEffect, useRef, useState } from 'react';
import './UpdateSystemFullScreenModal.css';
import { selectMachineToUpdateVersionButton, setNotificationVisibility, setSystemUpdateFullScreenModal, showUpdateMachineVersionNotification } from '../../modules/actions/action';
import { connect } from 'react-redux';
import { getSystemVersionDetails } from '../../helper/SystemVersionDetailsHelper';
import { postMachineVersionUpdate } from '../../helper/MachineVersionUpdateHelper';

const UpdateSystemFullScreenModal = (props) => {
    const [dataContainer, setDataContainer] = useState([]);
    const [latestThreeVersion, setLatestThreeVersion] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState([]);
    const [newVersionData,setNewVersionData]=useState(null);
    const [disableButton,setDisableButton]=useState(true);
    const latestThreeSystemVersion = [];

    const ref = useRef(true);
    const handleModalVisibility = () => {
        props.closeModal();
        props.openNotification();
    };
    const handleModalClose = () => {
        props.closeModal();
    };

    const handleOnChange = (e) => {
        console.log(e.target.value);
        setSelectedVersion(e.target.value);
        console.log("Selected Version to send to the Machine: ", JSON.parse(e.target.value).newSoftwareVersion,JSON.parse(e.target.value).newOsVersion);
        console.log("Machine Selectd Data: ", props.selectedMachineToUpdateVersion);
        setSelectedVersion({newSoftwareVersion:JSON.parse(e.target.value).newSoftwareVersion,newOSVersion:JSON.parse(e.target.value).newOsVersion});
    };

    useEffect(()=>{
        setNewVersionData(selectedVersion);
        setDisableButton(false);
        console.log("Selected Version",selectedVersion);
        // console.log
    },[selectedVersion]);

    const handleMachineUpdateVersion = () => {
        const dataArray = props.selectedMachineToUpdateVersion.map((machineUDI) => ({
            machineUDI,
            newSoftwareVersion: selectedVersion.newSoftwareVersion,
            newOSVersion: selectedVersion.newOSVersion
        }));
        console.log("Machine Version Update Data", dataArray);

        const machineData = {
            data: dataArray
        };

        const data = JSON.stringify(machineData);
        console.log("Machine Data: ", data);
        postMachineVersionUpdate(data)
            .then((res) => {
                console.log("Response: " + res);
                props.showMachineUpdateNotification();
            })
            .catch((err) => {
                console.log("ERROR RESPONSE", err.response.data.message);
            })
    };

    useEffect(() => {
        if (ref.current === true) {
            handleSystemVersionDataGet();
        }
        return () => {
            ref.current = false;
        }
    }, [ref]);

    const handleSystemVersionDataGet = () => {
        getSystemVersionDetails()
            .then((res) => {
                let data = res.data.data;
                setDataContainer(data);
            })
            .catch((err) => {
                console.log(err);
            })
    };


    const getLatestThreeVersion = () => {
        console.log("Data is running");
        console.log(dataContainer);
        const latestThree = dataContainer.slice(0, 3); // Get the first three elements from dataContainer
        setLatestThreeVersion(latestThree);
    };

    useEffect(() => {
        getLatestThreeVersion();
    }, [dataContainer]);

    return (
        <div className='full_screen_modal_container'>
            <div className='full_screen_modal'>
                <form>
                    <p>Select System Version</p>
                    {latestThreeVersion.map((sysVersion) => (
                        <div className='system_version_container' key={sysVersion.systemVersion}>
                            <div className='system_version_container_card'>
                                <input type="radio" name="system_version" id="system_version_1" value={JSON.stringify({newSoftwareVersion:sysVersion.softwareVersion,newOsVersion:sysVersion.osVersion})} onChange={(e) => handleOnChange(e)} />
                                {/* <input type="radio" name="system_version" id="system_version_1" value={JSON.stringify({data:sysVersion.softwareVersion})} onChange={(e)=>handleOnChange(e)}/> */}
                                {/* { "newSoftwareVersion": sysVersion.softwareVersion, "newOSVersion": sysVersion.osVersion } */}
                                <div>
                                    <h3>Software Version {sysVersion.softwareVersion}</h3>
                                </div>
                                <div>
                                    <h3>Os Version {sysVersion.osVersion}</h3>
                                </div>
                            </div>
                            <p>{sysVersion.description}</p>
                        </div>
                    ))}
                    <div className='system_version_button'>
                        <button type="button" onClick={handleModalClose}>Cancel</button>
                        <button type="button" onClick={() => { handleModalVisibility(); handleMachineUpdateVersion() }} disabled={disableButton} style={disableButton ? {"backgroundColor":"#6D1DF0", "opacity":"0.2"} : {}}>Send</button>
                        {/* <button type="button" onClick={() => { handleModalVisibility(); handleMachineUpdateVersion() }} disabled={!selectedVersion.length ? true : false} style={!selectedVersion.length ? {"backgroundColor":"#6D1DF0", "opacity":"0.2"} : {}}>Send</button> */}
                    </div>
                </form>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        showModal: state.systemUpdateFullScreenModal.showSystemUpdateModal,
        showNotification: state.showNotification.showNotificatin,
        selectedMachineToUpdateVersion: state.selectMachineToUpdateVersion.selectedMachineToUpdateVersion
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => { dispatch(setSystemUpdateFullScreenModal(true)) },
        closeModal: () => { dispatch(setSystemUpdateFullScreenModal(false)) },
        openNotification: () => { dispatch(setNotificationVisibility(true)) },
        closeNotification: () => { dispatch(setNotificationVisibility(false)) },
        selectMachineToUpdateVersion: () => { dispatch((selectMachineToUpdateVersionButton())) },
        showMachineUpdateNotification: () => {dispatch((showUpdateMachineVersionNotification(true)))},
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSystemFullScreenModal);