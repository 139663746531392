import React, { useState, useRef, useEffect } from 'react';
import './UploadSystemVersionFullScreenModal.css';
import cross_svg from '../../common/icons/cross.svg';
import { setUploadSystemVersionFullScreenModal } from '../../modules/actions/action';
import { setNotificationVisibility } from '../../modules/actions/action';
import { connect } from 'react-redux';
import UploadingProgress from '../UploadingProgress/UploadingProgress';
import UploadSystemVersionSuccessful from '../UploadSystemVersionSuccessful/UploadSystemVersionSuccessful';
import AWS, { CostExplorer } from 'aws-sdk';
import { getSystemVersionDetails } from '../../helper/SystemVersionDetailsHelper';
import { saveSoftwareVersionUploadHelper } from '../../helper/SystemVersionUploadHelper/SoftwareVersionUploadHelper';
import { showSystemVersionUploadNotification } from '../../modules/actions/action';
import { SOFTWARE_FILES, FIRMWARE_FILES, STMOS_FILES, IOMT_FILES } from './FILES_ENUM';
import { uploadFile } from '../../adapters/SystemVersionUploadAdapter/UploadFiles';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS,
    secretAccessKey: process.env.REACT_APP_AWS,
    region: REGION,
});
const s3 = new AWS.S3();
AWS.config.region = REGION;

const UploadSystemVersionFullScreenModal = (props) => {

    const [selectedSoftwareFiles, setSelectedSoftwareFiles] = useState([]);
    const [selectedFirmwareFiles, setSelectedFirmwareFiles] = useState([]);
    const [selectedStmosFiles, setSelectedStmosFiles] = useState([]);
    const [selectedIomtFiles, setSelectedIomtFiles] = useState([]);

    const softwareFileInputRef = useRef(null);
    const iomtFileInputRef = useRef(null);
    const firmwareFileInputRef = useRef(null);
    const stmosFileInputRef = useRef(null);

    const [softwareVersionUploadProgress, setSoftwareVersionUploadProgress] = useState(false);
    const [firmwareVersionUploadProgress, setFirmwareVersionUploadProgress] = useState(false);
    const [stmosVersionUploadProgress, setStmosVersionUploadProgress] = useState(false);
    const [iomtVersionUploadProgress, setIomtVersionUploadProgress] = useState(false);

    const [showSoftwareUploadProgress, setShowSoftwareUploadProgress] = useState(true);
    const [showFirmwareUploadProgress, setShowFirmwareUploadProgress] = useState(true);
    const [showIomtUploadProgress, setShowIomtUploadProgress] = useState(true);
    const [showStmosUploadProgress, setShowStmosUploadProgress] = useState(true);

    const [softwareFileUploadPercentage, setSoftwareFileUploadPercentage] = useState(0);
    const [firmwareFileUploadPercentage, setFirmwareFileUploadPercentage] = useState(0);
    const [stmosFileUploadPercentage, setStmosFileUploadPercentage] = useState(0);
    const [iomtFileUploadPercentage, setIomtFileUploadPercentage] = useState(0);

    const [softwareUploadMB, setSoftwareUploadMB] = useState(0);
    const [firmwareUploadMB, setFirmwareUploadMB] = useState(0);
    const [stmosUploadMB, setStmosUploadMB] = useState(0);
    const [iomtUploadMB, setIomtUploadMB] = useState(0);

    const [softwareUploadError, setSoftwareUploadError] = useState({});
    const [firmwareUploadError, setFirmwareUploadError] = useState({});
    const [stmosUploadError, setStmosUploadError] = useState({});
    const [iomtUploadError, setIomtUploadError] = useState({});

    const [softwareTotalMB, setSoftwareTotalMB] = useState(0);
    const [firmwareTotalMB, setFirmwareTotalMB] = useState(0);
    const [stmosTotalMB, setStmosTotalMB] = useState(0);
    const [iomtTotalMB, setIomtTotalMB] = useState(0);

    const [uploadMB, setUploadMB] = useState(0);
    const [totalMB, setTotalMB] = useState(0);
    const [uploadError, setUploadError] = useState(false);

    const [latestSystemVersion, setLatestSystemVersion] = useState({
        "softwareVersion": "0.0.0",
        "osVersion": "0.0.0",
    });
    const [lastSystemVersionDetails, setLastSystemVersionDetails] = useState(
        {
            "softwareVersion": "0.0.0",
            "osVersion": "0.0.0",
            "hmiUiVersion": "0.0.0",
            "hmiBackendVersion": "0.0.0",
            "fMasterVersion": "0.0.0",
            "fSlaveVersion": "0.0.0",
            "stmPyAppVersion": "0.0.0",
            "stmCAppVersion": "0.0.0",
            "stmOsVersion": "0.0.0",
            "iomtPyAppVersion": "0.0.0",
            "iomtJAppVersion": "0.0.0",
            "iomtOsVersion": "0.0.0",
            "description": "",
            "uploadDate": new Date(),
            "activeStatus": true
        }
    );
    // Previous system version details
    const [updateSystemVersionDetails, setUpdateSystemVersionDetails] = useState({}); // Update system version details
    const [description, setDescription] = useState(""); // Description of the system version
    const [apiResponse, setApiResponse] = useState("");
    const systemVersionRef = useRef(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileCategory, setFileCategory] = useState('');

    
    const getLatestSystemVersion = () => getSystemVersionDetails().then((res) => {
        const systemVersionsArray = res.data.data;
        const latestSystemVer = systemVersionsArray.at(0);
        setLastSystemVersionDetails(latestSystemVer);
        setLatestSystemVersion({
            "softwareVersion": latestSystemVer.softwareVersion,
            "osVersion": latestSystemVer.osVersion
        });
        setUpdateSystemVersionDetails({
            "softwareVersion": latestSystemVer.softwareVersion,
            "osVersion": latestSystemVer.osVersion
        });
    })
        .catch((error) => {
            setLatestSystemVersion(error);
        });

    useEffect(() => {
        getLatestSystemVersion();
    }, []);

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        console.log(e.target.value);
    };

    const handleFileChange = (event, fileCat) => {
        console.log("handleFileChange", event.target.files)
        const newSelectedFiles = Array.from(event.target.files);
        setSelectedFiles(newSelectedFiles);
        setFileCategory(fileCat);
    };

    useEffect(() => {
        if (selectedFiles.length) {
            switch (fileCategory) {
                case 'software':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'software') {
                        setSelectedSoftwareFiles(selectedFiles);
                    } else {
                        setSoftwareUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }));
                    }
                    break;
                case 'firmware':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'firmware') {
                        setSelectedFirmwareFiles(selectedFiles);
                    } else {
                        setFirmwareUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }));
                    }
                    break;
                case 'stmos':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'stmOs') {
                        setSelectedStmosFiles(selectedFiles);
                    } else {
                        setStmosUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }));
                    }
                    break;
                case 'iomt':
                    if (selectedFiles[0]?.webkitRelativePath.split('/')[0] === 'iomtOs') {
                        setSelectedIomtFiles(selectedFiles);
                    } else {
                        setIomtUploadError((prev) => ({
                            ...prev,
                            wrongFileUploadError: true
                        }))
                    }
                    break;
                default:
                    break;
            }
        }
    }, [fileCategory, selectedFiles]);

    // Description of the system will be updated when the system description changes each time. 
    useEffect(() => {
        setLastSystemVersionDetails((prevState) => ({
            ...prevState,
            description: description
        }))
    }, [description]);

    const handleUploadButtonClass = () => (
        ((updateSystemVersionDetails.softwareVersion != lastSystemVersionDetails.softwareVersion) ||
            (updateSystemVersionDetails.osVersion != lastSystemVersionDetails.osVersion)) ? false : true);

    useEffect(() => {
        handleUploadButtonClass();
    }, [updateSystemVersionDetails]);

    const handleSystemVersionUpload = async () => {
        console.log("Last System Version Details: ", lastSystemVersionDetails);
        let versionData = lastSystemVersionDetails;
        console.log("Version: ".versionData);
        await saveSoftwareVersionUploadHelper(versionData)
            .then((response) => {
                console.log("RESPONSE OF UPLOAD: ", response);
                // if(response.success === false){
                setApiResponse(response);
                props.showSystemVersionUploadNotification();
                props.closeModal();
                // window.location.reload(false);
                // }
            }).catch((error) => {
                console.log("API EROOR RES", error);
            });

        setUpdateSystemVersionDetails({
            "softwareVersion": versionData.softwareVersion,
            "osVersion": versionData.osVersion
        });
    };

    const uploadSoftwareFileToAWSFolder = async (filePath, folderName ,allFiles, index) => {
        console.log("FilePath", filePath);
        const softwareVersionFolder = parseInt(latestSystemVersion.softwareVersion.toString().split('.')[1]) + 1;
        try {
            // let filesToUpload = [filePath];
            uploadFile(filePath, ('software/' + softwareVersionFolder), (progress) => {
                const { loaded, total } = progress;
                const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                const uploadProgress = Math.round((loaded / total) * 100);
                console.log(`Upload progress: ${uploadProgress}%`);
                setSoftwareFileUploadPercentage(uploadProgress);
                setSoftwareUploadMB(loadedMB);
                setSoftwareTotalMB(totalMB);
                if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                    setShowSoftwareUploadProgress(false);
                }
            });
        } catch (error) {
            console.log(`Error uploading file: ${error.message}`);
        };
        //const fileKey = `software/${softwareVersionFolder}/${filePath.name}`;
        // const params = {
        //     Bucket: S3_BUCKET,
        //     Key: fileKey,
        //     Body: filePath,
        // };

        // const uploadRequest = s3.upload(params);
        // uploadRequest.on('httpUploadProgress', (progress) => {
        //     const { loaded, total } = progress;
        //     const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //     const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);

        //     const uploadProgress = Math.round((loaded / total) * 100);
        //     setSoftwareFileUploadPercentage(uploadProgress);
        //     setSoftwareUploadMB(loadedMB);
        //     setSoftwareTotalMB(totalMB);
        //     if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //         setShowSoftwareUploadProgress(false);
        //     }
        // });

        // uploadRequest.send((err, data) => {
        //     if (err) {
        //         console.log(`Error uploading file`, err);
        //         reject(err);
        //     } else {
        //         console.log(`${filePath.name} uploaded successfully`, data);
        //         resolve(data);
        //     }
        // })
    };
    const uploadFirmwareFileToAWSFolder = async (filePath, folderName, allFiles, index) => {
        const firmwareVersionFolder = parseInt(latestSystemVersion.softwareVersion.toString().split('.')[0]) + 1;
        try {
            uploadFile(filePath, ('firmware/' + firmwareVersionFolder), (progress) => {
                const { loaded, total } = progress;
                console.log("loaded", loaded, "total", total);
                const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                const uploadProgress = Math.round((loaded / total) * 100);
                console.log(`Upload progress: ${uploadProgress}%`);
                setFirmwareFileUploadPercentage(uploadProgress);
                setFirmwareUploadMB(loadedMB);
                setFirmwareTotalMB(totalMB);
                if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                    setShowFirmwareUploadProgress(false);
                }
            });
        } catch (error) {
            console.log(`Error uploading file: ${error.message}`);
        };
        // return new Promise((resolve, reject) => {
        //     try {
        //         const fileKey = `firmware/${firmwareVersionFolder}/${filePath.name}`;

        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: fileKey,
        //             Body: filePath,
        //         };

        //         const uploadRequest = s3.upload(params);
        //         uploadRequest.on('httpUploadProgress', (progress) => {
        //             const { loaded, total } = progress;
        //             const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //             const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);

        //             const uploadProgress = Math.round((loaded / total) * 100);
        //             setFirmwareFileUploadPercentage(uploadProgress);
        //             setFirmwareUploadMB(loadedMB);
        //             setFirmwareTotalMB(totalMB);
        //             if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //                 setShowFirmwareUploadProgress(false);
        //             }
        //         });

        //         uploadRequest.send((err, data) => {
        //             if (err) {
        //                 console.log(`Error uploading file`, err);
        //                 reject(err);
        //             } else {
        //                 console.log(`File uploaded successfully`);
        //                 resolve(data)
        //             }
        //         })
        //     } catch (error) {
        //         console.log(`Error uploading file: ${error.message}`);
        //     }
        // })
    };
    const uploadStmosFileToAWSFolder = async (filePath, folderName, allFiles, index) => {
        const stmosVersionUpload = parseInt(latestSystemVersion.osVersion.toString().split('.')[1]) + 1;
        try {
            uploadFile(filePath, ('stmOs/' + stmosVersionUpload), (progress) => {
                const { loaded, total } = progress;
                const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                const uploadProgress = Math.round((loaded / total) * 100);
                console.log(`Upload progress: ${uploadProgress}%`);
                setStmosFileUploadPercentage(uploadProgress);
                setStmosUploadMB(loadedMB);
                setStmosTotalMB(totalMB);
                if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                    setShowStmosUploadProgress(false);
                }
            });
        } catch (error) {
            console.log(`Error uploading file: ${error.message}`);
        };
        // return new Promise((resolve, reject) => {
        //     try {
        //         const fileKey = `stmOs/${stmosVersionUpload}/${filePath.name}`;

        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: fileKey,
        //             Body: filePath,
        //         };

        //         const fileType = folderName.split('/')[0];
        //         const uploadRequest = s3.upload(params, {
        //             onProgress: (progress) => {
        //                 console.log("progress:", progress.loaded, "/", progress.total);
        //             }
        //         });
        //         uploadRequest.on('httpUploadProgress', (progress) => {
        //             const { loaded, total } = progress;
        //             const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //             const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
        //             const uploadProgress = Math.round((loaded / total) * 100);
        //             setStmosFileUploadPercentage(uploadProgress);
        //             setStmosUploadMB(loadedMB);
        //             setStmosTotalMB(totalMB);
        //             if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //                 setShowStmosUploadProgress(false);
        //             }
        //         });

        //         uploadRequest.send((err, data) => {
        //             if (err) {
        //                 console.log(`Error uploading file`, err);
        //                 reject(err);
        //             } else {
        //                 console.log(`File uploaded successfully`);
        //                 resolve(data);
        //             }
        //         })

        //     } catch (error) {
        //         console.log(`Error uploading file: ${error.message}`);
        //     }
        // })
    };
    const uploadIomtFileToAWSFolder = async (filePath, folderName, allFiles, index) => {
        const iomtVersionFolder = parseInt(latestSystemVersion.osVersion.toString().split('.')[0]) + 1;

        try {
            uploadFile(filePath, ('iomtOs/' + iomtVersionFolder), (progress) => {
                const { loaded, total } = progress;
                const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
                const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
                const uploadProgress = Math.round((loaded / total) * 100);
                console.log(`Upload progress: ${uploadProgress}%`);
                setIomtFileUploadPercentage(uploadProgress);
                setIomtUploadMB(loadedMB);
                setIomtTotalMB(totalMB);
                if ((uploadProgress === 100) && (index + 1 === allFiles)) {
                    setShowIomtUploadProgress(false);
                }
            });
        } catch (error) {
            console.log(`Error uploading file: ${error.message}`);
        };
        // return new Promise((resolve, reject) => {
        //     try {
        //         const fileKey = `iomtOs/${iomtVersionFolder}/${filePath.name}`;

        //         const params = {
        //             Bucket: S3_BUCKET,
        //             Key: fileKey,
        //             Body: filePath,
        //         };

        //         const fileType = folderName.split('/')[0];
        //         const uploadRequest = s3.upload(params);
        //         uploadRequest.on('httpUploadProgress', (progress) => {
        //             const { loaded, total } = progress;
        //             const loadedMB = Math.round((loaded / (1024 * 1024))).toFixed(0);
        //             const totalMB = Math.round((total / (1024 * 1024))).toFixed(0);
        //             const uploadProgress = Math.round((loaded / total) * 100);
        //             setIomtFileUploadPercentage(uploadProgress);
        //             setIomtUploadMB(loadedMB);
        //             setIomtTotalMB(totalMB);
        //             if ((uploadProgress === 100) && (index + 1 === allFiles)) {
        //                 setShowIomtUploadProgress(false);
        //             }
        //         });

        //         uploadRequest.send((err, data) => {
        //             if (err) {
        //                 console.log(`Error uploading file`, err);
        //                 reject(err);
        //             } else {
        //                 console.log(`File uploaded successfully`);
        //                 resolve(data);
        //             }
        //         })

        //     } catch (error) {
        //         console.log(`Error uploading file: ${error.message}`);
        //     }
        // })
    };


    // Use Effects to call AWSUpload function on selected categories file changes

    const [updateSystemVersion, setUpdateSystemVersion] = useState({
        updateSoftwareVersion: false,
        updateFirmwareVersion: false,
        updateStmosVersion: false,
        updateIomtVersion: false
    });

    const [isSoftwareUpdatedSystemVersion, setIsSoftwareUpdatedSystemVersion] = useState(false);
    const [isFirmwareUpdatedSystemVersion, setIsFirmwareUpdatedSystemVersion] = useState(false);
    const [isStmostUpdatedSystemVersion, setIsStmostUpdatedSystemVersion] = useState(false);
    const [isImotUpdatedSystemVersion, setIsImotUpdatedSystemVersion] = useState(false);


    // Version checks before upload
    const shouldUploadFile = (file, lastFileVersion) => {
        return new Promise((resolve, reject) => {
            const fileVersion = file.name.split('_')[1];

            let currentMajor = +fileVersion.split('.')[0];
            let currentMinor = +fileVersion.split('.')[1];
            let currentProduction = +fileVersion.split('.')[2];

            let lastMajor = +lastFileVersion.split('.')[0];
            let lastMinor = +lastFileVersion.split('.')[1];
            let lastProduction = +lastFileVersion.split('.')[2];

            if (currentMajor > lastMajor || (currentMajor === lastMajor && currentMinor > lastMinor) || (currentMajor === lastMajor && currentMinor === lastMinor && currentProduction > lastProduction)) {
                console.log(`Ok! so you have new version :) , ${file.name}`);
                resolve(true);
            } else {
                console.log(`YOu are uploading the older version of =:^<^>^:= , ${file.name}`);
                resolve(false);
            }

            reject(true);

        });
    };

    const sortFileArray = (filesData) => {
        const files = filesData;
        console.log("sortFileArray", files);
        const fileNames = [];
        for (const i of files) {
            fileNames.push(i.name);
        }
        const sortedFileNames = fileNames.sort();
        const sortedFilesData = [];
        for (const name of sortedFileNames) {
            for (const i of files) {
                if (name === i.name) {
                    sortedFilesData.push(i);
                }
            }
        }
        return sortedFilesData;
    };

    useEffect(() => {
        // console.log("updateSystemVersin called!", updateSystemVersion);
        if (updateSystemVersion.updateSoftwareVersion === true) {
            let arr = latestSystemVersion.softwareVersion.toString().split('.');
            if (isSoftwareUpdatedSystemVersion === false) {
                // console.log("System Version updated by software!");
                arr[1] = parseInt(arr[1]) + 1;
                setIsSoftwareUpdatedSystemVersion(true);
            }
            const newSystemVersion = arr.join('.');
            setLatestSystemVersion((prevState) => ({
                ...prevState,
                softwareVersion: newSystemVersion
            }));
            setLastSystemVersionDetails((prevState) => ({
                ...prevState,
                softwareVersion: newSystemVersion,
            }));
            // console.log(arr);
            // console.log(latestSystemVersion);
        }

        if (updateSystemVersion.updateFirmwareVersion === true) {
            let arr = latestSystemVersion.softwareVersion.toString().split('.');

            if (isFirmwareUpdatedSystemVersion === false) {
                // console.log("System Version updated by Firmware!");
                arr[0] = parseInt(arr[0]) + 1;
                setIsFirmwareUpdatedSystemVersion(true);
            }
            // if (parseInt(latestSystemVersion.toString().split('.')[2]) === parseInt(arr[2])) {
            //     // console.log("System Version updated by Firmware!");
            //     arr[2] = parseInt(arr[2]) + 1;
            // }

            const newSystemVersion = arr.join('.');
            setLatestSystemVersion((prevState) => ({
                ...prevState,
                softwareVersion: newSystemVersion
            }));
            setLastSystemVersionDetails((prevState) => ({
                ...prevState,
                softwareVersion: newSystemVersion,
            }));
        }

        if (updateSystemVersion.updateStmosVersion === true) {
            let arr = latestSystemVersion.osVersion.toString().split('.');
            // console.log("Inside UpdateSystemVersion", arr);

            if (isStmostUpdatedSystemVersion === false) {
                // console.log("System Version updated by Stmos!");
                arr[1] = parseInt(arr[1]) + 1;
                setIsStmostUpdatedSystemVersion(true);
            }

            const newSystemVersion = arr.join('.');
            // console.log(newSystemVersion);
            setLatestSystemVersion((prevState) => ({
                ...prevState,
                osVersion: newSystemVersion
            }));
            setLastSystemVersionDetails((prevState) => ({
                ...prevState,
                osVersion: newSystemVersion,
            }));
        }

        if (updateSystemVersion.updateIomtVersion === true) {
            let arr = latestSystemVersion.osVersion.toString().split('.');

            if (isImotUpdatedSystemVersion === false) {
                // console.log("System Version updated by IOmt!");
                arr[0] = parseInt(arr[0]) + 1;
                setIsImotUpdatedSystemVersion(true);
            }

            const newSystemVersion = arr.join('.');
            setLatestSystemVersion((prevState) => ({
                ...prevState,
                osVersion: newSystemVersion
            }));
            setLastSystemVersionDetails((prevState) => ({
                ...prevState,
                osVersion: newSystemVersion,
            }));
        }
    }, [updateSystemVersion]);

    const uploadSelectedSoftwareFiles = async () => {
        console.log('SOFTWARE_FILES', SOFTWARE_FILES);
        if (selectedSoftwareFiles.length < SOFTWARE_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${SOFTWARE_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedSoftwareFiles);
        let FeFileEligibleForUpload = false;
        let BeFileEligibleForUpload = false;
        let eligibleFiles = [];
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedSoftwareFile = sortedFiles[i];
                if (selectedSoftwareFile.name.split('_')[0] === 'BE') {
                    const { hmiBackendVersion } = lastSystemVersionDetails;
                    BeFileEligibleForUpload = await shouldUploadFile(selectedSoftwareFile, hmiBackendVersion);
                    console.log(`Running the shouldUploadFile for ${selectedSoftwareFile.name} in software`);
                    eligibleFiles.push(selectedSoftwareFile);
                    
                } else if (selectedSoftwareFile.name.split('_')[0] === 'UI') {
                    const { hmiUiVersion } = lastSystemVersionDetails;
                    FeFileEligibleForUpload = await shouldUploadFile(selectedSoftwareFile, hmiUiVersion);
                    console.log(`Running the shouldUploadFile for ${selectedSoftwareFile.name} in software }}`);
                    eligibleFiles.push(selectedSoftwareFile);
                }
            }
            
            if (eligibleFiles?.length >= 2 && (FeFileEligibleForUpload || BeFileEligibleForUpload)){
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    console.log("eligibleFile", eligibleFile);
                    await uploadSoftwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    setUpdateSystemVersion({ ...updateSystemVersion, updateSoftwareVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'BE') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            hmiBackendVersion: versionNumber
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'UI') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${versionNumber}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            hmiUiVersion: versionNumber
                        }))
                    }
                }
            }
            else{
                alert('No eligible file to upload, Please try again with correct files')
                return
            }
        } catch (error) {
            console.log("CATCH", eligibleFiles.length);
            // console.error('Error uploading files:', error)
            // if (eligibleFiles.length === 0) {
            //     setSoftwareUploadError(true);
            // };
            console.log("SOFTWARE CATCH ERROR", softwareUploadError);
            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                await uploadSoftwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                setUpdateSystemVersion({ ...updateSystemVersion, updateSoftwareVersion: true });
                if (eligibleFile.name.split('_')[0] === 'BE') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log("This is the changed file versionNumber", versionNumber);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        hmiBackendVersion: versionNumber
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'UI') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log(`This is the ${eligibleFile.name}`);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        hmiUiVersion: versionNumber
                    }))
                }
            }
        }
    };

    useEffect(() => {
        console.log("INSIDE SOFTWARE USEeFFECT ");
        if (selectedSoftwareFiles?.length) {
            uploadSelectedSoftwareFiles();
        }
    }, [selectedSoftwareFiles]);

    //FIRMWARE
    const uploadSelectedFirmwareFiles = async () => {
        if (selectedFirmwareFiles.length < FIRMWARE_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${FIRMWARE_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedFirmwareFiles);
        let eligibleFiles = [];
        let masterFileEligibleForUpload = false;
        let slaveFileEligibleForUpload = false;
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedFirmwareFile = sortedFiles[i];
                if (selectedFirmwareFile.name.split('_')[0] === 'Master') {
                    const { fMasterVersion } = lastSystemVersionDetails;
                    masterFileEligibleForUpload = await shouldUploadFile(selectedFirmwareFile, fMasterVersion);
                    eligibleFiles.push(selectedFirmwareFile);
                    
                } else if (selectedFirmwareFile.name.split('_')[0] === 'Slave') {
                    const { fSlaveVersion } = lastSystemVersionDetails;
                    slaveFileEligibleForUpload = await shouldUploadFile(selectedFirmwareFile, fSlaveVersion);
                    eligibleFiles.push(selectedFirmwareFile);
                }
            }
            if (eligibleFiles?.length >= 2 && (masterFileEligibleForUpload || slaveFileEligibleForUpload)){
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    await uploadFirmwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    setUpdateSystemVersion({ ...updateSystemVersion, updateFirmwareVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'Master') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        setLastSystemVersionDetails(Object.assign({}, lastSystemVersionDetails, { fMasterVersion: versionNumber }));
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            fMasterVersion: versionNumber
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'Slave') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            fSlaveVersion: versionNumber
                        }))
                    }
                }
            }
            else{
                alert('No eligible file to upload, Please try again with correct files.')
                return
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                await uploadFirmwareFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                setUpdateSystemVersion({ ...updateSystemVersion, updateFirmwareVersion: true });
                if (eligibleFile.name.split('_')[0] === 'Master') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        fMasterVersion: versionNumber
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'Slave') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        fSlaveVersion: versionNumber
                    }))
                }
            }
        }
    };

    useEffect(() => {
        if (selectedFirmwareFiles?.length){
            uploadSelectedFirmwareFiles();
        }
    }, [selectedFirmwareFiles]);

    //STMOS
    const uploadSelectedStmosFiles = async () => {
        if (selectedStmosFiles.length < STMOS_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${STMOS_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedStmosFiles);
        let eligibleFiles = [];
        let OSFileAvailable = false;
        let InstallFileAvailable = false;
        let PyFileAvailable = false;
        let CappFileAvailable = false;
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedStmosFile = sortedFiles[i];
                if (selectedStmosFile.name.split('_')[0] === 'CAPP') {
                    const { stmCAppVersion } = lastSystemVersionDetails;
                    CappFileAvailable = await shouldUploadFile(selectedStmosFile, stmCAppVersion);
                    eligibleFiles.push(selectedStmosFile);

                } else if (selectedStmosFile.name.split('_')[0] === 'OS') {
                    const { stmOsVersion } = lastSystemVersionDetails;
                    OSFileAvailable = await shouldUploadFile(selectedStmosFile, stmOsVersion);
                    eligibleFiles.push(selectedStmosFile);
                } else if (selectedStmosFile.name.split('_')[0] === 'PY') {
                    const { stmPyAppVersion } = lastSystemVersionDetails;
                    PyFileAvailable = await shouldUploadFile(selectedStmosFile, stmPyAppVersion);
                    eligibleFiles.push(selectedStmosFile);
                } else if (selectedStmosFile.name.split('_')[0] === 'Install') {
                    const { stmOsVersion } = lastSystemVersionDetails;
                    //checking for OS version as Install and OS version always remains same
                    InstallFileAvailable = await shouldUploadFile(selectedStmosFile, stmOsVersion);
                    eligibleFiles.push(selectedStmosFile);
                }
            }

            if (!InstallFileAvailable) {
                alert("Install file is missing.");
                return;
            }

            // if (!(OSFileAvailable || PyFileAvailable) ) {
            //     alert("OS or PY file is missing.");
            //     return;
            // }
            if (eligibleFiles?.length) {
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    await uploadStmosFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    setUpdateSystemVersion({ ...updateSystemVersion, updateStmosVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'CAPP') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            stmCAppVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'OS') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`This is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            stmOsVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'PY') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            stmPyAppVersion: versionNumber,
                        }));
                    }
                }
            }
            else{
                alert('No eligible file to upload, Please try again with correct files.')
                return
            }
        } catch (error) {
            console.log(`Error uploading files:`, error);
            console.log(eligibleFiles.length);
            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                console.log(eligibleFile.name, eligibleFile);
                await uploadStmosFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                setUpdateSystemVersion({ ...updateSystemVersion, updateStmosVersion: true });
                if (eligibleFile.name.split('_')[0] === 'CAPP') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log("This is the changed file versionNumber", versionNumber);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        stmCAppVersion: versionNumber,
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'OS') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log(`THis is the ${eligibleFile.name}`);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        stmOsVersion: versionNumber,
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'PY') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log(`THis is the ${eligibleFile.name}`);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        stmPyAppVersion: versionNumber,
                    }));
                }
            }
        }
    };

    useEffect(() => {
        if (selectedStmosFiles?.length){
            uploadSelectedStmosFiles();
        }
    }, [selectedStmosFiles]);


    //IOMT
    const uploadSelectedIomtFiles = async () => {
        if (selectedIomtFiles.length < IOMT_FILES['MIN_NUM_FILES_REQUIRED']) {
            alert(`Minimum number of files required is ${IOMT_FILES['MIN_NUM_FILES_REQUIRED']}`);
            return;
        }
        const sortedFiles = sortFileArray(selectedIomtFiles);
        let eligibleFiles = [];
        let OSFileAvailable = false;
        let InstallFileAvailable = false;
        let PyFileAvailable = false;
        let JappFileAvailable = false;
        try {
            for (let i = 0; i < sortedFiles.length; i++) {
                const selectedIomtFile = sortedFiles[i];
                if (selectedIomtFile.name.split('_')[0] === 'JAPP') {
                    const { iomtJAppVersion } = lastSystemVersionDetails;
                    JappFileAvailable = await shouldUploadFile(selectedIomtFile, iomtJAppVersion);
                    eligibleFiles.push(selectedIomtFile);
                } else if (selectedIomtFile.name.split('_')[0] === 'OS') {
                    const { iomtOsVersion } = lastSystemVersionDetails;
                    OSFileAvailable = await shouldUploadFile(selectedIomtFile, iomtOsVersion);
                    eligibleFiles.push(selectedIomtFile);
                } else if (selectedIomtFile.name.split('_')[0] === 'PY') {
                    const { iomtPyAppVersion } = lastSystemVersionDetails;
                    PyFileAvailable = await shouldUploadFile(selectedIomtFile, iomtPyAppVersion);
                    eligibleFiles.push(selectedIomtFile);
                } else if (selectedIomtFile.name.split('_')[0] === 'Install') {
                    InstallFileAvailable = true;
                    const { iomtOsVersion } = lastSystemVersionDetails;
                    //checking for OS version as Install and OS version always remains same
                    InstallFileAvailable = await shouldUploadFile(selectedIomtFile, iomtOsVersion);
                    eligibleFiles.push(selectedIomtFile);
                }
            }
            // if (!(OSFileAvailable || PyFileAvailable)) {
            //     alert("OS or PY file is missing.");
            //     return;
            // }
            console.log("=== InstallFileAvailable > ", InstallFileAvailable, 'OSFileAvailable > ', OSFileAvailable, 'JappFileAvailable > ', JappFileAvailable, 'PyFileAvailable > ', PyFileAvailable, "===")
            if (!(InstallFileAvailable || OSFileAvailable || JappFileAvailable || PyFileAvailable)) {
                alert("Files missing.");
                return;
            }
            if (eligibleFiles?.length) {
                for (let i = 0; i < eligibleFiles.length; i++) {
                    const eligibleFile = eligibleFiles[i];
                    console.log(eligibleFile.name, eligibleFile);
                    await uploadIomtFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                    setUpdateSystemVersion({ ...updateSystemVersion, updateIomtVersion: true });
                    if (eligibleFile.name.split('_')[0] === 'JAPP') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log("This is the changed file versionNumber", versionNumber);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            iomtJAppVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'OS') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            iomtOsVersion: versionNumber,
                        }));
                    } else if (eligibleFile.name.split('_')[0] === 'PY') {
                        const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                        console.log(`THis is the ${eligibleFile.name}`);
                        setLastSystemVersionDetails((prevState) => ({
                            ...prevState,
                            iomtPyAppVersion: versionNumber,
                        }));
                    }
                }
            }
            else{
                alert('No eligible file to upload, Please try again with correct files.')
                return
            }
        } catch (error) {
            for (let i = 0; i < eligibleFiles.length; i++) {
                const eligibleFile = eligibleFiles[i];
                console.log(eligibleFile.name, eligibleFile);
                await uploadIomtFileToAWSFolder(eligibleFile, eligibleFile?.webkitRelativePath, eligibleFiles.length, i);
                setUpdateSystemVersion({ ...updateSystemVersion, updateIomtVersion: true });
                if (eligibleFile.name.split('_')[0] === 'JAPP') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log("This is the changed file versionNumber", versionNumber);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        iomtJAppVersion: versionNumber,
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'OS') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log(`THis is the ${eligibleFile.name}`);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        iomtOsVersion: versionNumber,
                    }));
                } else if (eligibleFile.name.split('_')[0] === 'PY') {
                    const versionNumber = eligibleFile.name.split("_")[1].substring(0, eligibleFile.name.split("_")[1].lastIndexOf("."));
                    console.log(`THis is the ${eligibleFile.name}`);
                    setLastSystemVersionDetails((prevState) => ({
                        ...prevState,
                        iomtPyAppVersion: versionNumber,
                    }));
                }
            }
        }
    }

    useEffect(() => {
        if (selectedIomtFiles?.length){
            uploadSelectedIomtFiles();
        }
    }, [selectedIomtFiles]);


    const handleButtonClick = () => {
        if (softwareFileInputRef.current !== null) {
            softwareFileInputRef.current.click();
        }
        if (iomtFileInputRef.current !== null) {
            iomtFileInputRef.current.click();
        }
        if (firmwareFileInputRef.current !== null) {
            firmwareFileInputRef.current.click();
        }

        if (stmosFileInputRef.current !== null) {
            stmosFileInputRef.current.click();
        }

    };

    useEffect(() => {
        if (selectedSoftwareFiles.length > 0) {
            handleUploadProgress('software');
        }
        if (selectedFirmwareFiles.length > 0) {
            handleUploadProgress('firmware');
        }
        if (selectedStmosFiles.length > 0) {
            handleUploadProgress('stmos');
        }
        if (selectedIomtFiles.length > 0) {
            handleUploadProgress('iomt');
        }

        return () => {

        }
    }, [selectedSoftwareFiles, selectedFirmwareFiles, selectedIomtFiles, selectedStmosFiles]);

    const handleCloseButton = () => {
        props.closeModal();
    };

    const handleUploadProgress = (fileCategory) => {
        switch (fileCategory) {
            case 'software':
                if (selectedSoftwareFiles.length > 0 && softwareVersionUploadProgress === false) {
                    setSoftwareVersionUploadProgress((prev) => !prev);
                }
                break;
            case 'firmware':
                if (selectedFirmwareFiles.length > 0 && firmwareVersionUploadProgress === false) {
                    setFirmwareVersionUploadProgress((prev) => !prev);
                }
                break;
            case 'stmos':
                if (selectedStmosFiles.length > 0 && stmosVersionUploadProgress === false) {
                    setStmosVersionUploadProgress((prev) => !prev);
                }
                break;
            case 'iomt':
                if (selectedIomtFiles.length > 0 && iomtVersionUploadProgress === false) {
                    setIomtVersionUploadProgress((prev) => !prev);
                }
                break;
            default:
                break;
        };
    };

    const handleVersionProgressCrossIcon = (e, versionType) => {
        switch (versionType) {
            case 'software':
                // console.log("Software Cross Icon detected");
                setSoftwareVersionUploadProgress((prev) => !prev);
                break;
            case 'firmware':
                // console.log("Firmware Cross Icon detected");
                setFirmwareVersionUploadProgress((prev) => !prev);
                break;
            case 'stmos':
                // console.log("STMOS Cross Icon detected");
                setStmosVersionUploadProgress((prev) => !prev);
                break;
            case 'iomt':
                // console.log("IOMT Cross Icon detected");
                setIomtVersionUploadProgress((prev) => !prev);
                break;

            default:
                break;
        }
    };

    const handleTryAgain = () => {
        console.log(`Try Again clicked!`);
        // setSoftwareVersionUploadProgress(false);
        // setUploadError(false);
        // setSelectedSoftwareFiles([]);
    };

    console.log("stmosUploadError.wrongFileUploadError>>", stmosUploadError.wrongFileUploadError);

    return (
        <div className='full_screen_modal_container'>
            <div className='full_screen_modal'>
                <form>
                    <div className='upload_version_header'>
                        <h3>Upload Update</h3>
                        <img src={cross_svg} onClick={handleCloseButton} />
                    </div>
                    
                    <div className={`upload_version_section ${softwareVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                        {softwareVersionUploadProgress ? (
                            showSoftwareUploadProgress ? (
                                <UploadingProgress
                                    handleTryAgain={handleTryAgain}
                                    uploadError={softwareUploadError}
                                    countProgress={softwareFileUploadPercentage}
                                    handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'software')}
                                    uploadMB={softwareUploadMB}
                                    totalMB={softwareTotalMB}
                                />
                            ) : (
                                <UploadSystemVersionSuccessful files={selectedSoftwareFiles} />
                            )
                        ) : (
                            <>
                                {softwareUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>Software Version</label>}
                                <div>
                                    <label className={`${softwareUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={softwareFileInputRef}
                                            onChange={(e) => handleFileChange(e, 'software')}
                                            webkitdirectory=""
                                        />
                                        Upload
                                    </label>
                                </div>
                            </>
                        )}
                    </div>

                    <div className={`upload_version_section ${firmwareVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                        {firmwareVersionUploadProgress ? (
                            showFirmwareUploadProgress ? (
                                <UploadingProgress
                                    handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'firmware')}
                                    handleTryAgain={handleTryAgain}
                                    uploadError={firmwareUploadError}
                                    countProgress={firmwareFileUploadPercentage}
                                    uploadMB={firmwareUploadMB}
                                    totalMB={firmwareTotalMB}
                                />
                            ) : (
                                <UploadSystemVersionSuccessful files={selectedFirmwareFiles} />
                            )
                        ) : (
                            <>
                                {firmwareUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>Firmware Version</label>}
                                <div>
                                    <label className={`${firmwareUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={firmwareFileInputRef}
                                            onChange={(e) => handleFileChange(e, 'firmware')}
                                            webkitdirectory=""
                                        />
                                        Upload
                                    </label>
                                </div>
                            </>
                        )}
                    </div>

                    <div className={`upload_version_section ${stmosVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                        {stmosVersionUploadProgress ? (
                            showStmosUploadProgress ? (
                                <UploadingProgress
                                    handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'stmos')}
                                    handleTryAgain={handleTryAgain}
                                    uploadError={stmosUploadError}
                                    countProgress={stmosFileUploadPercentage}
                                    uploadMB={stmosUploadMB}
                                    totalMB={stmosTotalMB}
                                />
                            ) : (
                                <UploadSystemVersionSuccessful files={selectedStmosFiles} />
                            )
                        ) : (
                            <>
                                {stmosUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>STMOS Version</label>}
                                {/* <label>STMOS Version</label> */}
                                <div>
                                    <label className={`${stmosUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={stmosFileInputRef}
                                            onChange={(e) => handleFileChange(e, 'stmos')}
                                            webkitdirectory=""
                                        />
                                        Upload
                                    </label>
                                </div>
                            </>
                        )}
                    </div>


                    <div className={`upload_version_section ${iomtVersionUploadProgress ? '' : 'upload_version_section_padding'}`}>
                        {iomtVersionUploadProgress ? (
                            showIomtUploadProgress ? (
                                <UploadingProgress
                                    handleVersionProgressCrossIcon={(e) => handleVersionProgressCrossIcon(e, 'iomt')}
                                    handleTryAgain={handleTryAgain}
                                    uploadError={iomtUploadError}
                                    countProgress={iomtFileUploadPercentage}
                                    uploadMB={iomtUploadMB}
                                    totalMB={iomtTotalMB}
                                />
                            ) : (
                                <UploadSystemVersionSuccessful files={selectedIomtFiles} />
                            )
                        ) : (
                            <>
                                {iomtUploadError.wrongFileUploadError ? <label style={{ color: "red", fontWeight: "500" }}>Uploading the wrong file <span className='wrong_file_error'>: )</span></label> : <label>IOMT Version</label>}
                                {/* <label>IOMT Version</label> */}
                                <div>
                                    <label className={`${iomtUploadError.wrongFileUploadError ? "version_upload_button version_upload_button_error" : "version_upload_button"}`}>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={iomtFileInputRef}
                                            onChange={(e) => handleFileChange(e, 'iomt')}
                                            webkitdirectory=""
                                        />
                                        Upload
                                    </label>
                                </div>
                            </>
                        )}
                    </div>

                    <div className='system_version_details_container'>
                        <div>
                            <input
                                type='text'
                                placeholder='Software Version '
                                value={latestSystemVersion.softwareVersion}
                                onChange={(e) => (e)}
                            />
                            {lastSystemVersionDetails.softwareVersion ? <span>Software Version</span> : ""}
                        </div>
                        <div>
                            <input
                                type='text'
                                placeholder='Os Version '
                                value={latestSystemVersion.osVersion}
                                onChange={(e) => (e)}
                            />
                            {latestSystemVersion.osVersion ? <span>Os Version</span> : ""}
                        </div>
                    </div>
                    <div className='version_details'>
                        <textarea
                            type='textarea'
                            placeholder='Description'
                            value={description}
                            onChange={(e) => handleDescriptionChange(e)}
                        />
                    </div>
                    {<span className={apiResponse.success === false ? "error_style" : "success_style"}>{apiResponse.message}</span>}
                    <div className='system_update_button'>
                        <button type="button" onClick={handleCloseButton}>Cancel</button>
                        <button
                            type="button"
                            onClick={(e) => handleSystemVersionUpload(e)}
                            disabled={handleUploadButtonClass()}
                            className={handleUploadButtonClass() ? "upload_button_disable" : ""}
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        showModal: state.uploadSystemVersionFullScreenModal.showUploadSystemVersionModal,
        showNotification: state.showNotification.showNotificatin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openModal: () => { dispatch(setUploadSystemVersionFullScreenModal(true)) },
        closeModal: () => { dispatch(setUploadSystemVersionFullScreenModal(false)) },
        openNotification: () => { dispatch(setNotificationVisibility(true)) },
        closeNotification: () => { dispatch(setNotificationVisibility(false)) },
        showSystemVersionUploadNotification: () => { dispatch(showSystemVersionUploadNotification(true)) }

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UploadSystemVersionFullScreenModal);