import React from 'react';
import './UploadSystemVersionSuccessful.css';
import success_check_svg from '../../common/icons/green_success_dot.svg';
import file_svg from '../../common/icons/file_icon.svg';

const UploadSystemVersionSuccessful = (props) => {
    return (
        <div className='successful_upload_complete_container'>
            <div className=''>
                <p className='version_details' >Software Version Upload Successful</p>
                <img src={success_check_svg} />
            </div>
            <div className='file_details'>
                {
                    props.files.map((file) => {
                        return (
                            <div key={file.name}>
                                <img src={file_svg} width={25} />
                                <ul>
                                    {/* <li>UI_876.8765.876</li> */}
                                    <li>{file.name}</li>
                                    <li>{file.name.split('_')[0]} Version</li>
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default UploadSystemVersionSuccessful;