// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const showSystemInactiveFullScreenModalReducer = (state = initialState.showSystemInactiveFullScreenModalReducer, action) => {
    switch (action.type) {
        case Types.TOGGLE_SYSTEM_INACTIVE_FULL_SCREEN_MODAL:
            return { ...state, showSystemInactiveModal: action.payload};
        default:
            return state;
    };
};