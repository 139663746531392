import axios from "axios";
import "../../common/axiosInterceptor/axios-interceptor";

export const MachineVersionUpdateAPI = async (data) => {
    console.log("MachineVersionUpdateAPI", data)
    return axios({
        method: 'post',
        headers: {'content-type': 'application/json'},
        url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/updateVersionForMachine`,
        data: data,
        authRequired: true
    })
}