import React, { useState, useEffect } from 'react';
import './StatusModal.css';
import { UpdateMachineServiceRequest } from '../../helper/RequestCallbackHelper';

const StatusModal = ({ id, isOpen, onRequestClose, onSave, currentStatus, position }) => {
  const [status, setStatus] = useState(currentStatus);
  const [remark, setRemark] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setIsSaveDisabled(remark.trim() === '');
  }, [remark]);

  const handleSave = () => {
    const data = {
      id: id,
      remark: remark,
      status: status
    };
    UpdateMachineServiceRequest(data)
      .then((res) => {
        onSave(status, remark);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectStatus = (selectedStatus) => {
    setStatus(selectedStatus);
    setIsDropdownOpen(false);
  };

  return (
    <div
      className="status-modal"
      style={{
        display: isOpen ? 'block' : 'none',
        top: position.top,
        left: position.left
      }}
    >
      <div className="modal-content">
        <div className="form-group">
          <label>Action</label>
          <div className="dropdown" onClick={toggleDropdown}>
            <div className={`dropdown-selected ${status}`}>
              {status?.charAt(0).toUpperCase() + status?.slice(1)}
            </div>
            {isDropdownOpen && (
              <div className="dropdown-modal">
                <ul>
                  <li className="pending" onClick={() => selectStatus('pending')}>Pending</li>
                  <li className="acknowledged" onClick={() => selectStatus('acknowledged')}>Acknowledged</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>
            Remark <span className='redText'>*</span>
          </label>
          <textarea
            value={remark}
            maxlength="255"
            onChange={(e) => setRemark(e.target.value)}
            placeholder="Remark"
            required
          ></textarea>
        </div>
        <div className="modal-actions">
          <button className="cancel-button" onClick={onRequestClose}>Cancel</button>
          <button className="save-button" onClick={handleSave} disabled={isSaveDisabled}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
