import React, { useState, useEffect, useRef } from 'react';
import './MachineVersionDetails.css'
import ReactTable from '../../components/ReactTable/ReactTable';
import { Link, useParams } from "react-router-dom";
import { TIMELINE_CONST } from '../../common/constant/common-constants';
import { getMachineVersionDetails } from '../../helper/MachineVersionDetailsHelper';
import StatusButton from '../../components/StatusButton/StatusButton';
import moment from 'moment';

const MachineDetails = () => {
    const { machine_sn } = useParams();
    const ref = useRef(true);

    const [dataContainer, setDataContainer] = useState([]);
    const handleMachineDataGet = () => {
        getMachineVersionDetails(machine_sn)
            .then((res) => {
                setDataContainer(res.data);
            })
            .catch((err) => {
                console.error(err);
            })
    };
    useEffect(() => {
        // Only fetch the machine data on first render.
        if (ref.current === true) {
            handleMachineDataGet();
        }
        return () => {
            ref.current = false;
        }
    }, []);


    const getTableColumn = () => {
        const columns = [
            {
                title: 'UPDATED FROM',
                dataIndex: 'previousVersion',
                key: 'previousVersion',
                className: 'previous_version_class header_column',
                children: [
                    {
                        title: 'SYSTEM',
                        dataIndex: '',
                        key: '',
                        className: 'header_column fixed_column',
                        render: (value, row) => {
                            if (value === null) {
                                return (
                                    <span>-</span>
                                )
                            } else {
                                return (
                                    <span>{row.updatedFrom.softwareVersion}</span>
                                )
                            }
                        }
                    }, {
                        title: 'OS',
                        dataIndex: '',
                        key: '',
                        className: 'header_column fixed_column',
                        render: (value, row) => {
                            if (value === null) {
                                return (
                                    <span>-</span>
                                )
                            } else {
                                return (
                                    <span>{row.updatedFrom.osVersion}</span>
                                )
                            }
                        }
                    }
                ]
            },
            {
                title: 'UPDATED TO',
                dataIndex: 'updatedVersion',
                key: 'updatedVersion',
                className: 'updated_version_class header_column',
                children: [
                    {
                        title: 'SYSTEM',
                        dataIndex: '',
                        key: '',
                        className: 'header_column fixed_column',
                        render: (value, row) => {
                            if (value === null) {
                                return (
                                    <span>-</span>
                                )
                            } else {
                                return (
                                    <span>{row.updatedTo.softwareVersion}</span>
                                )
                            }
                        }
                    }, {
                        title: 'OS',
                        dataIndex: '',
                        key: '',
                        className: 'header_column fixed_column',
                        render: (value, row) => {
                            if (value === null) {
                                return (
                                    <span>-</span>
                                )
                            } else {
                                return (
                                    <span>{row.updatedTo.osVersion}</span>
                                )
                            }
                        }
                    }
                ]
            },
            {
                title: 'DATE',
                dataIndex: 'systemUpdateDate',
                key: 'systemUpdateDate',
                className: 'update_time_class header_column',
                render: (value, row) => {
                    if (value === null || value === undefined) {
                        return <span>-</span>
                    } else {
                        return <span>{moment(value).format(TIMELINE_CONST.DATE_FORMAT)}</span>
                    }
                }
            },
            {
                title: 'TIME',
                dataIndex: 'systemUpdateDate',
                key: 'systemUpdateDate',
                className: 'update_time_class header_column',
                render: (value, row) => {
                    if (value === null || value === undefined) {
                        return <span>-</span>
                    } else {
                        return <span>{moment(value).format(TIMELINE_CONST.TIME_FORMAT)}</span>
                    }
                }
            },
            {
                title: 'STATUS',
                dataIndex: 'systemUpdateStatus',
                key: 'systemUpdateStatus',
                className: 'ots_status_class header_column',
                render: (value, row) => {
                    if (value === "successful") {
                        return (
                            <div>
                                <StatusButton value={value} bg="#DFF6E4" color="#21A366" />
                            </div>
                        )
                    } else if (value === "rollback") {
                        return (
                            <div>
                                <StatusButton value={value} bg="#DFF9FB" color="#1ECFDB" />
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <StatusButton value={value} bg="#FFD6D6" color="#FF0D0D" />
                            </div>
                        )
                    }
                }
            }
        ];

        return columns;
    };

    useEffect(() => {
        getTableData();
    }, [dataContainer])

    const getTableData = () => {
        // let filteredData;
        // if (dataContainer === null) {
        //     filteredData = [];
        // } else {
        //     filteredData = dataContainer;
        // }
        return dataContainer;
    }
    return (
        <div className='machine-version-details'>
            <div className='machine-version-detail-sub-header'>
                <p><Link to='/ota-details/machine-details/'>Dashboard</Link> <span>{'>'}</span> {machine_sn}</p>
                <h2>{machine_sn}</h2>
            </div>
            <div className='machine-version-details-table'>
                <ReactTable columns={getTableColumn()} data={getTableData()} rowKey="sentVersion" />
            </div>
        </div>
    )
}

export default MachineDetails;