import React, { useEffect, useState } from 'react'
import './InactiveSystemVersionDetails.css'
import SystemDetailsSubHeader from '../../components/SystemDetailsSubHeader/SystemDetailsSubHeader'
import ReactTable from '../../components/ReactTable/ReactTable'
import { redirect, useNavigate } from 'react-router-dom'
import { getMachineDetails } from '../../helper/MachineDetailsHelper'
import { PAGE_CONST } from '../../common/constant/common-constants'

const InactiveSystemVersionDetails = () => {
  const navigate = useNavigate();

  const [dataContainer, setDataContainer] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] = useState(false);
  // const [getMachineVersionDetails, setMachineVersionDetails] = useState(false) 

  const handleHeaderCheckboxChange = () => {
    const allKeys = dataContainer.map((row) => row.machine_sn);
    // console.log(allKeys);
    setSelectedRows(isHeaderCheckboxSelected ? [] : allKeys);
    setIsHeaderCheckboxSelected(!isHeaderCheckboxSelected);
  };

  const handleRowCheckboxChange = (rowKey) => {
    // console.log(rowKey);
    const updatedSelectedRows = selectedRows.includes(rowKey)
      ? selectedRows.filter((key) => key !== rowKey)
      : [...selectedRows, rowKey];
    setSelectedRows(updatedSelectedRows);
  };

  useEffect(() => {
    handleInactiveSystemVersionDataGet();
  }, [])

  const handleInactiveSystemVersionDataGet = () => {
    getMachineDetails()
      .then((res) => {
        console.log(res);
        setDataContainer(res);
      })
      .catch((err) => {
        console.log(err);
      })
  };
  // const handleDetailRedirection = (machine_sn) => {

  //   console.log("redirecting to orderId :: " + machine_sn);
  //   redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_DETAIL, { machine_sn: machine_sn });
  // };
  const handleRowSelection = (rowKey) => {
    if (selectedRows.includes(rowKey)) {
      setSelectedRows(selectedRows.filter((key) => key !== rowKey));
    } else {
      setSelectedRows([...selectedRows, rowKey]);
    }
  };

  const handleRowClick = (rowKey, index, event) => {
    const cellToIgnore = 'checkbox';
    if (event.target && event.target.type === 'checkbox') {
      // Ignore the event for checkbox cell 
      return;
    }
    // console.log('Row clicked', rowKey.machine_sn);
    //  Handle the row click event for other cells
    redirect(navigate, PAGE_CONST.MACHINE_DETAILS, rowKey);
    // navigate(`/ota-details/machine-details/${rowKey.machine_sn}`);
  };
  const getTableColumn = () => {
    const columns = [
      {
        title: 'SYSTEM VERSION',
        dataIndex: 'machine_sn',
        key: 'production_ormachine_snder_id',
        className: 'sticky_column fixed_column header_column',
        children: [
          {
            title: ' ',
            dataIndex: '',
            key: '',
            className: 'sticky_column header_column fixed_column',
          }
        ]
      },
      {
        title: 'DATE',
        dataIndex: 'update_date',
        key: 'current_version',
        className: 'sticky_column fixed_column header_column',
        children: [
          {
            title: ' ',
            dataIndex: '',
            key: '',
            className: 'sticky_column header_column fixed_column',
          }
        ]
      },
      {
        title: 'SOFTWARE VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'header_column fixed_column',
        children: [
          {
            title: 'UI',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',
          }, {
            title: 'BACKEND',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',
          }
        ]
      },
      {
        title: 'FIRMWARE VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'header_column fixed_column',
        children: [
          {
            title: 'UI',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',
          }, {
            title: 'BACKEND',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',

          }
        ]
      },
      {
        title: 'STMOS VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'header_column fixed_column',
        children: [
          {
            title: 'UI',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',

          }, {
            title: 'BACKEND',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',
          }
        ]
      },
      {
        title: 'IOMT VERSION',
        dataIndex: 'machine_ota_status',
        key: 'machine_ota_status',
        className: 'header_column fixed_column',
        children: [
          {
            title: 'UI',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',
          }, {
            title: 'BACKEND',
            dataIndex: '',
            key: '',
            className: 'header_column fixed_column',
          }
        ]
      },
    ];

    return columns;
  };

  const getTableData = () => {
    // console.log(JSON.stringify(dataContainer));
    let data;
    console.log("Data",data);
    if (Array.isArray(dataContainer)) {
      data = dataContainer;
    } else {
      data = [];
    }

    const filteredData = data;
    console.log("FIle Data",filteredData);
    return filteredData;
  }
  return (
    <div className='inactive-system-version-details'>
      <div>
        <SystemDetailsSubHeader />
      </div>
      <div className='inactive-system-update-details-table'>
        <ReactTable columns={getTableColumn()} data={getTableData()} rowKey="mahcineUDI" className='react-table' />
      </div>
    </div>
  )
}

export default InactiveSystemVersionDetails