// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const activateSystemUpdateButtonReducer = (state = initialState.activateUpdateSystemButtonReducer, action) => {
    switch (action.type) {
        case Types.TOGGLE_SYSTEM_UPDATE_VERSION_BUTTON:
            return { ...state, activeUpdateSystemButton: action.payload};
        default:
            return state;
    };
};