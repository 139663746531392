export const Types = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    CHANGE_SIDEBAR_ACTIVE_ICON: "CHANGE_SIDEBAR_ACTIVE_ICON",
    TOGGLE_SYSTEM_UPDATE_VERSION_BUTTON: "TOGGLE_SYSTEM_UPDATE_VERSION_BUTTON",
    TOGGLE_SYSTEM_UPDATE_FULL_SCREEN_MODAL: "TOGGLE_SYSTEM_UPDATE_FULL_SCREEN_MODAL",
    CHANGE_NOTIFICATION_VISIBILITY: "CHANGE_NOTIFICATION_VISIBILITY",
    TOGGLE_SYSTEM_INACTIVE_FULL_SCREEN_MODAL: "TOGGLE_SYSTEM_INACTIVE_FULL_SCREEN_MODAL",
    TOGGLE_SYSTEM_INACTIVE_VERSION_BUTTON: "TOGGLE_SYSTEM_INACTIVE_VERSION_BUTTON",
    TOGGLE_UPLOAD_SYSTEM_VERSION_FULL_SCREEN_MODAL: "TOGGLE_UPLOAD_SYSTEM_VERSION_FULL_SCREEN_MODAL",
    SELECT_MACHINE_TO_UPDATE_VERSION: "SELECT_MACHINE_TO_UPDATE_VERSION",
    SHOW_UPDATE_MACHINE_VERSION_NOTIFICATION: "SHOW_UPDATE_MACHINE_VERSION_NOTIFICATION",
    SHOW_SYSTEM_VERSION_UPLOAD_NOTIFICATION: "SHOW_SYSTEM_VERSION_UPLOAD_NOTIFICATION",
};  