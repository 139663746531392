// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const showNotificationReducer = (state = initialState.showNotificationReducer, action) => {
    switch (action.type) {
        case Types.CHANGE_NOTIFICATION_VISIBILITY:
            return { ...state, showNotificatin: action.payload};
        default:
            return state;
    };
};