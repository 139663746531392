import { MachineDetailsAPI } from "../adapters/MachineVersionDetailsAdapter/MachineDetailsAdapter"
export const getMachineDetails = () => {

    var promise = new Promise(function (resolve, reject) {
        MachineDetailsAPI().then((res) => {
            if (res && res.data && res.status === 200) {
                let response = res.data;
                // console.log("Machine Details Helper Call",response);
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in order details Api :: " + err);
        });
    })

    return promise;
};