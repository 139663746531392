import axios from "axios";
import "../../common/axiosInterceptor/axios-interceptor";

export const UpdateMachineServiceRequestApi = async (data) => {
    console.log("UpdateMachineServiceRequest", data)
    return axios({
        method: 'put',
        headers: {'content-type': 'application/json'},
        url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/updateMachineServiceRequest`,
        data: data,
        authRequired: true
    })
}