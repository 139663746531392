// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const showSystemVersionUploadNotification = (state = initialState.uploadSystemVersionNotificationReducer, action) => {
    switch (action.type) {
        case Types.SHOW_SYSTEM_VERSION_UPLOAD_NOTIFICATION:
            return { ...state, showUploadSystemVersionNotification: action.payload};
        default:
            return state;
    };
};