// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const showSystemUpdateFullScreenModalReducer = (state = initialState.showSystemUpdateFullScreenModalReducer, action) => {
    switch (action.type) {
        case Types.TOGGLE_SYSTEM_UPDATE_FULL_SCREEN_MODAL:
            return { ...state, showSystemUpdateModal: action.payload};
        default:
            return state;
    };
};