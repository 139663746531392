export const SOFTWARE_FILES = {
    MIN_NUM_FILES_REQUIRED: 2,
    REQUIRED_FILES: ['BE', 'UI']
}

export const FIRMWARE_FILES = {
    MIN_NUM_FILES_REQUIRED: 2,
    REQUIRED_FILES: ['MASTER', 'SLAVE']
}

export const STMOS_FILES = {
    MIN_NUM_FILES_REQUIRED: 1,
    REQUIRED_FILES: ['OS', 'INSTALL']
}

export const IOMT_FILES = {
    MIN_NUM_FILES_REQUIRED: 1,
    REQUIRED_FILES: ['OS', 'INSTALL']
}
