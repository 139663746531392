import { DemoMachinesList } from "../adapters/DemoMachines/DemoMachinesList";
export const getDemoMachinesList = (data) => {
    var promise = new Promise(function (resolve, reject) {
        DemoMachinesList({
            runTimeDays: data
        }).then((res) => {
            if (res && res.data && res.status === 200) {
                let response = res.data;
                resolve(response);
            } else {
                if (res && res.data && res.data.error && res.data.error.message) reject(res.data.error.message);
                else reject("error occured");
            }
        }).catch((err) => {
            console.log("error occured in order details Api :: " + err);
        });
    })

    return promise;
};