// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'


export const showUploadSystemVersionFullScreenModalReducer = (state = initialState.showUploadSystemVersionFullScreenModalReducer, action) => {
    switch (action.type) {
        case Types.TOGGLE_UPLOAD_SYSTEM_VERSION_FULL_SCREEN_MODAL:
            return { ...state, showUploadSystemVersionModal: action.payload};
        default:
            return state;
    };
};