import { SoftwareVersionUploadAPI } from "../../adapters/SystemVersionUploadAdapter/SoftwareVersionUploadAdapter";
export const saveSoftwareVersionUploadHelper = (versionData) => {
    console.log("Software Version UPload API ");

    return (new Promise((resolve, reject) => {
        SoftwareVersionUploadAPI(versionData)
            .then((res) => {
                console.log("RESPONSE FROM THE API",res.response);
                if (res && res.data && res.status === 200) {
                    resolve("Now getting response", res);
                }

                if (res && res.success && res.data && res.status === 500) {
                    resolve("Error Occured", res.data, res.status);
                }

                if(res && res.response.data.success===false){
                    resolve(res.response.data);
                }

            }).catch((err) => {
                console.log("Catch Error Occured", err);
                reject(err);
            })
    }));
};