import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import logo_svg from "../../common/icons/logo.svg";
import "./Login.css";
import { login } from '../../helper/login-helper';
import { redirect } from '../../common/utils/redirectionutils';
import { LOGIN_CONST } from '../../common/constant/page-constants';
import { PAGE_CONST } from '../../common/constant/common-constants';
import { setUserDetails } from '../../modules/actions/action';


function Login(props) {
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);


    const handleSubmit = () => {
        if (isSubmitDisabled) return;

        setErrorMsg(null);

        let data = {
            email: email,
            password: password
        }
        login(data).then((res) => {
            props.setUserDetails({
                email: res.email,
                userRole: res.role
            });
            redirect(navigate,PAGE_CONST.HOME);
        }).catch((err) => {
            setErrorMsg("Error Occurred !!!");
            console.log(err);
        })
    }

    const handleChange = (type, event) => {

        let value = (event.target && event.target.value) || '';
        setIsSubmitDisabled(true);
        setErrorMsg(null);
        switch (type) {

            case LOGIN_CONST.EMAIL:
                setEmail(value);
                if (value && password) setIsSubmitDisabled(false);
                break;
            case LOGIN_CONST.PASSWORD:
                setPassword(value);
                if (email && value) setIsSubmitDisabled(false);
                break;
            default:
                break;
        }
    }

    return (
        <div 
            className='login-container'
            onKeyDown={(event) => {
                if(event.key === 'Enter'){
                    handleSubmit()
                }
              }
            }
        >
            <div className='left-panel' />
            <div className='right-panel'>
                <div className='content-container'>
                    <div className='logo-container'>
                        <img className='login-logo' src={logo_svg} alt="logo" />
                        <h1 className='sign-in-text-container'>
                            Admin dashboard
                        </h1>
                    </div>
                    <h2 className='sub-text'>OTA dashboard to manage sytem updates.</h2>
                    <div className='login-form-container'>
                        <div className='email-container'>
                            <input
                                className='login-input email'
                                type='email'
                                placeholder='Email ID'
                                onChange={(event) => handleChange(LOGIN_CONST.EMAIL, event)} />
                        </div>
                        <div className='password-container'>
                            <input
                                className='login-input password'
                                type="password"
                                placeholder='Password'
                                onChange={(event) => handleChange(LOGIN_CONST.PASSWORD, event)}
                            />
                        </div>
                        <div className='error-msg'>
                            {errorMsg}
                        </div>
                        <div className='sign-in-botton-container'>
                            <button
                                className={'sign-in sign-in-botton'}
                                onClick={() => handleSubmit()}
                            >
                                Sign In
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = (dispatch) => {

    return {
        setUserDetails: (data) => { dispatch(setUserDetails(data)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);