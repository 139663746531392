
import { combineReducers } from 'redux';
import {activeIconReducer} from '../reducer/activeIconReducer';
import {activateSystemUpdateButtonReducer} from '../reducer/activeSystemVersionButtonReducer';
import { showSystemUpdateFullScreenModalReducer } from './showSystemUpdateFullScreenModal';
import { showNotificationReducer } from './showNotification';
import { showSystemInactiveFullScreenModalReducer } from './showSystemInactiveFullScreenModalReducer';
import { inactiveSystemVersionButtonReducer } from './inactiveSystemVersionButtonReducer';
import { showUploadSystemVersionFullScreenModalReducer } from './showUploadSystemVersionFullScreenModalReducer';
import { selectMachineToUpdateVersionReducer } from './selectMachineToUpdateVersion';
import { showMachineUpdateNotificationReducer } from './showMachineVersionUpdateNotification';
import { showSystemVersionUploadNotification } from './showSystemVersionUploadNotification';
import { userDetailsReducer } from './userDetailsReducer';
// import other reducers if any

export const reducer = combineReducers({
  activeIcon: activeIconReducer,
  updateSystemIcon: activateSystemUpdateButtonReducer,
  inactiveSystemIcon: inactiveSystemVersionButtonReducer,
  systemUpdateFullScreenModal: showSystemUpdateFullScreenModalReducer,
  showNotification: showNotificationReducer,
  systemInactiveFullScreenModal: showSystemInactiveFullScreenModalReducer,
  uploadSystemVersionFullScreenModal: showUploadSystemVersionFullScreenModalReducer,
  selectMachineToUpdateVersion: selectMachineToUpdateVersionReducer,
  showMachineUpdateNotification: showMachineUpdateNotificationReducer,
  showSystemVersionUploadNotification: showSystemVersionUploadNotification,
  userDetails: userDetailsReducer,
  // other reducers
});

