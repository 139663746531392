import React, {useEffect, useRef, useCallback, useState} from "react";
import "./VentilatorAlarmTable.css";
import priority_high_svg from "../../common/icons/priority-high.svg";
import priority_medium_svg from "../../common/icons/priority-medium.svg";
import priority_low_svg from "../../common/icons/priority-low.svg";
import { covertDateString, covertTimeString, getQueryParams } from "../../common/utils/functionalUtils";
import { SET_PARAMETERS, MANEUVERS } from "./PARAMETER_ENUM";
import { MODE } from "./VENTILATOR_MODES_ENUM";
import { getVentilatorAlarmHistoryAPI } from "../../adapters/RequestCallbackAdapter/MachineAlarms.js";

export function VentilatorAlarmTable() {
    const [data, setData] = useState([]);
    const [endOffPage, setEndOffPage] = useState(false);
    const [createdAt, setCreatedAt] = useState(null);
    const [offset, setOffset] = useState(null);
    const [isFetchingData, setIsFetchingData] = useState(false)
    const tableRef = useRef(null);

    const getVentilatorAlarm = (created, offs) => {
        let params = getQueryParams(window.location.href)
        if (endOffPage) return;
        
        getVentilatorAlarmHistoryAPI(
            { 
                ventilatorId: params?.vid,
                filter: null,
                createdAt: created ? created?.toString() : null,
                limit: 100,
                offset: offs ? offs : null,
            }
        ).then((res) => {
            console.log(res);
            if (res?.ventilatorAlarms?.length < 100) setEndOffPage(true);
            const ventilatorAlarms = res.ventilatorAlarms;
            if (ventilatorAlarms) {
                setCreatedAt(ventilatorAlarms?.[ventilatorAlarms?.length - 1]?.createdAt);
                setData((prevData) => {
                    setOffset([...prevData, ...ventilatorAlarms].length);
                    return [...prevData, ...ventilatorAlarms]
                });
            }
        }).finally(() => {
            // Reset the flag when the request is complete
            setIsFetchingData(false);
          })
        .catch((err) => {
            console.log("alarm err: ", err);
        });
    }

    const handleScroll = useCallback(() => {
        const table = tableRef.current;
        if (table) {
            // Calculate the scroll position
            const scrollPosition = table.scrollTop + table.clientHeight;
            // Check if the scroll position is within the last 10 rows
            const isLastRows = scrollPosition >= table.scrollHeight - table.clientHeight * 0.1;
    
            if (isLastRows && !isFetchingData) {
                setIsFetchingData(true);
                // Call your function when the last 10 rows are reached
                getVentilatorAlarm(createdAt, offset);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdAt, offset, isFetchingData, setIsFetchingData]);

    const resetState = () => {
        setEndOffPage(false);
        setData([]);
        setCreatedAt(null);
        setOffset(null);
    }

    useEffect(() => {
        const table = tableRef.current;
        if (table) {
          table.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          // Cleanup: Remove the event listener when the component is unmounted
          if (table) {
            table.removeEventListener('scroll', handleScroll);
          }
        };
      }, [handleScroll]);


    useEffect(()=>{
        if (!offset) getVentilatorAlarm();
    },[offset]) 


    const getPriority = (priority) => {
        let pr = priority?.toUpperCase();
        switch (pr) {
            case "HIGH":
                return {icon: priority_high_svg, color: '#AD0606', textColor:'#fff'};
            case "MEDIUM":
                return {icon: priority_medium_svg, color: '#FCC331', textColor: '#000'};
            case "LOW":
                return {icon: priority_low_svg, color: '#3ADAE5', textColor:'#fff'};
            default:
                return {icon: '', color: '#3ADAE5'};
        }
    }

    let sortedEvents = data.sort((a, b)=> (a.observedTime > b.observedTime)) || [];
    let header = ["TYPE", "PRIORITY", "MESSAGE", "DATE", "TIME"];
    return (
        <div ref={tableRef} className="alarm-table-container">
            <table className="alarm-table">
                <thead className="sticky">
                    <tr>
                        {
                            header?.map((data, count) => {
                                return (
                                    <th key={count} className="alarm-header">{data}</th>
                                )
                            })
                        }
                    </tr>
                </thead >
                <tbody>
                    {
                        sortedEvents?.map((row, count) => {
                            let observedTime = row.observedTime?.toString();
                            // eslint-disable-next-line array-callback-return
                            if (!row.message) return;
                            let priorityData = getPriority(row.priority)
                            let color = row?.status === 'active' ? priorityData['color'] : '';
                            let textColor = row?.status === 'active' ? priorityData['textColor'] : '';
                            let type = '';
                            let words = row.type.split(' ');
                            words?.forEach((t)=>{
                                type += t[0].toUpperCase() + t.substring(1)?.toLowerCase() + ' ';
                            })
                            return (
                                <tr key={count}>
                                    <td key={row.type} style={{backgroundColor: color, color: textColor}}>{type}</td>
                                    <td key={row.priority} style={{backgroundColor: color}}>
                                        <div className="alarm-priority">
                                            {priorityData['icon'] && <img alt="priority icon" src={priorityData['icon']} className="priority" />}
                                        </div>
                                    </td>
                                    <td key={row.message} style={{backgroundColor: color, color:textColor}}>
                                        <div className="alarm-name">
                                            {row.message.includes("##") ?
                                                row.message.split("##")[1] ?
                                                <div> 
                                                    {{...MODE, ...SET_PARAMETERS, ...MANEUVERS}[row.message.split("##")[1]]?.TITLE_START}
                                                    <sub>{{...MODE, ...SET_PARAMETERS, ...MANEUVERS}[row.message.split("##")[1]]?.TITLE_SUB_SCRIPT}</sub> 
                                                    {{...MODE, ...SET_PARAMETERS, ...MANEUVERS}[row.message.split("##")[1]]?.TITLE_END + row.message.split("##")[2]}
                                                </div>
                                                : 
                                                    ''
                                            :
                                                row.message
                                            }
                                        </div>
                                    </td>
                                    <td key={covertDateString(observedTime)} style={{backgroundColor: color, color:textColor}}>
                                        <div className="alarm-date">{covertDateString(observedTime)}</div>
                                    </td>
                                    <td key={covertTimeString(observedTime)} style={{backgroundColor: color, color:textColor}}>
                                        <div className="alarm-time">{covertTimeString(observedTime)}</div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}