
import axios from "axios";
import "../../common/axiosInterceptor/axios-interceptor";

export const AssignHospitalApi = async (data) => {
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/admindashboard/api/assignHospital`,
        data: data,
        authRequired: true
    })
}