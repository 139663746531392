import React, { useEffect, useState } from 'react'
import './MachineDetails.css'
import moment from 'moment';
import SubHeader from '../../components/SubHeader/SubHeader'
import ReactTable from '../../components/ReactTable/ReactTable';
import { useNavigate } from "react-router-dom";
import { redirect } from '../../common/utils/redirectionutils';
import { PAGE_CONST, TIMELINE_CONST } from '../../common/constant/common-constants';
import { getMachineDetails } from '../../helper/MachineDetailsHelper';
import { connect } from 'react-redux';
import { selectMachineToUpdateVersionButton, setActiveSystemUpdateIcon } from '../../modules/actions/action';
import StatusButton from '../../components/StatusButton/StatusButton';
import MessageNotification from '../../components/MessageNotification/MessageNotification';
import { showUpdateMachineVersionNotification } from '../../modules/actions/action'

const MachineVersionDetails = (props) => {
  // console.log(props.updateSystemIcon);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [dataContainer, setDataContainer] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] = useState(false);

  console.log(selectedRows);

  useEffect(() => {
    props.selectMachineToUpdateVersion(selectedRows);
  }, [selectedRows]);

  const handleHeaderCheckboxChange = () => {
    const allKeys = dataContainer.map((row) => row.mahcineUDI);
    // console.log(allKeys);
    setSelectedRows(isHeaderCheckboxSelected ? [] : allKeys);
    setIsHeaderCheckboxSelected(!isHeaderCheckboxSelected);
  };

  const handleRowCheckboxChange = (rowKey) => {
    console.log("Row machine Number", rowKey);
    const updatedSelectedRows = selectedRows.includes(rowKey)
      ? selectedRows.filter((key) => key !== rowKey)
      : [...selectedRows, rowKey];
    setSelectedRows(updatedSelectedRows);
  };

  useEffect(() => {
    handleMachineDataGet();
  }, []);

  useEffect(() => {
    if (selectedRows.length || isHeaderCheckboxSelected.length) {
      props.activateSystemUpdateButton();
    } else {
      props.deactivateSystemUpdateButton();
    }
  }, [selectedRows, isHeaderCheckboxSelected]);

  const handleMachineDataGet = () => {
    getMachineDetails()
      .then((res) => {
        let data = res.data.data;
        // console.log("Get Machine Details", data);
        setDataContainer(data);
      })
      .catch((err) => {
        console.log(err);
      })
  };
  // const handleDetailRedirection = (machine_sn) => {

  //   console.log("redirecting to orderId :: " + machine_sn);
  //   redirect(navigate, PAGE_CONST.PRODUCTION_ORDER_DETAIL, { machine_sn: machine_sn });
  // };
  const handleRowSelection = (rowKey) => {
    if (selectedRows.includes(rowKey)) {
      setSelectedRows(selectedRows.filter((key) => key !== rowKey));
    } else {
      setSelectedRows([...selectedRows, rowKey]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const handleRowClick = (rowKey, index, event) => {
    // console.log("handleRowClick", rowKey.mahcineUDI, index, event);
    const cellToIgnore = 'checkbox';
    if (event.target && event.target.type === 'checkbox') {
      // Ignore the event for checkbox cell 
      return;
    }
    // console.log('Row clicked', rowKey.machine_sn);
    //  Handle the row click event for other cells
    redirect(navigate, PAGE_CONST.MACHINE_DETAILS, rowKey);
    // navigate(`/ota-details/machine-details/${rowKey.machine_sn}`);
  };
  const getTableColumn = () => {
    const columns = [
      {
        title: <input type="checkbox" checked={isHeaderCheckboxSelected} onChange={() => handleHeaderCheckboxChange()} />,
        dataIndex: 'checkbox',
        key: 'checkbox',
        width: 50,
        className: 'checkbox_class',
        render: (value, row) => (
          <>
            <input
              type="checkbox"
              checked={selectedRows.includes(row.mahcineUDI)}
              onChange={() => handleRowCheckboxChange(row.mahcineUDI)}
            />
            <span style={{backgroundColor: 'red'}}></span>
          </>
        ),
      },
      {
        title: 'MACHINE SERIAL NO.',
        dataIndex: 'mahcineUDI',
        key: 'mahcineUDI',
        className: 'machine_serial_number_id_class header_column',
        render: (value, row) => {
            console.log('MACHINE SERIAL NO', row);
            if (!value) return ""
            const machineSplit = value.split('-')
            return(
              <>
                {machineSplit.filter(x=>x.includes('1G'))}
              </>
          )
        }
      },
      {
        title: 'CURRENT SYSTEM VERSION',
        dataIndex: 'currentSoftwareVersion',
        key: 'currentSoftwareVersion',
        className: 'current_version_class header_column'
      },
      {
        title: 'CURRENT OS VERSION',
        dataIndex: 'currentOSVersion',
        key: 'currentOSVersion',
        className: 'current_version_class header_column'
      },
      {
        title: 'SENT SOFTWARE VERSION',
        dataIndex: 'sentSoftwareVersion',
        key: 'sentSoftwareVersion',
        className: 'sent_version_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: 'SENT OS VERSION',
        dataIndex: 'sentOsVersion',
        key: 'sentOsVersion',
        className: 'sent_version_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: 'OTA  STATUS',
        dataIndex: 'systemUpdateStatus',
        key: 'systemUpdateStatus',
        className: 'ots_status_class header_column',
        render: (value, row) => {
          if (value === "successful") {
            return (
              <div>
                <StatusButton value={value} bg="#DFF6E4" color="#21A366" />
              </div>
            )
          } else if (value === "Rollback") {
            return (
              <div>
                <StatusButton value={value} bg="#DFF9FB" color="#1ECFDB" />
              </div>
            )
          } else {
            return (
              <div>
                <StatusButton value={value} bg="#FFD6D6" color="#FF0D0D" />
              </div>
            )
          }

        }
      },
      {
        title: 'UPDATE DATE',
        dataIndex: 'systemUpdateDate',
        key: 'systemUpdateDate',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{moment(value).format(TIMELINE_CONST.DATE_FORMAT)}</span>
          }
        }
      },
      {
        title: 'UPDATE TIME',
        dataIndex: 'systemUpdateDate',
        key: 'systemUpdateDate',
        className: 'update_time_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{moment(value).format(TIMELINE_CONST.TIME_FORMAT)}</span>
          }
        }
      }
    ];

    return columns;
  };

  const getTableData = () => {
    let filteredData = [];

    if (Array.isArray(dataContainer)) {
      filteredData = dataContainer.filter(item =>
        item.mahcineUDI.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filteredData;
  };

  // table configuration or table event handlers
  const getTableConfig = () => {
    const tableConfig = {
      onRow: (record, index) => ({
        onClick: (event) => handleRowClick(record, index, event),
      }),
    }

    return tableConfig;
  };

  return (
    <div className='ots-details-container' style={{position:"relative"}}>
      {/* <div style={{backgroundColor:"red", color:"white", position:"absolute", left:"8%", top:"50%", height:"40px"}}>Notification!</div> */}
      {props.showUpdateNotification && <div style={{position:"absolute", left:"0%", top:"28%", width:'98%',  backdropFilter: "blur(20px)", marginLeft:"1%"}}>
        <MessageNotification systemVersionUpload={true}/>
      </div>}
      <SubHeader
        title='OTA VERSION DETAILS'
        handleSearchChange={handleSearchChange} 
        searchQuery={searchQuery}
        />
      <div className='machine-details-table'>
        <ReactTable columns={getTableColumn()} data={getTableData()} config={getTableConfig().onRow} rowKey="mahcineUDI" className="react-table" />
      </div>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    updateSystemIcon: state.updateSystemIcon.activeUpdateSystemButton,
    showUpdateNotification: state.showMachineUpdateNotification.showMachineVersionNotification
  };
};

const mapDispatchToProps = dispatch => {
  return {
    activateSystemUpdateButton: () => { dispatch(setActiveSystemUpdateIcon(true)) },
    deactivateSystemUpdateButton: () => { dispatch(setActiveSystemUpdateIcon(false)) },
    selectMachineToUpdateVersion: (machine) => { dispatch((selectMachineToUpdateVersionButton(machine))) },
    showMachineUpdateNotification: () => {dispatch((showUpdateMachineVersionNotification(true)))},
    closeMachineUpdateNotification: () => {dispatch((showUpdateMachineVersionNotification(false)))},
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineVersionDetails);