import { CONTAINER_CONST } from "../../common/constant/common-constants";

let initialState = {
    userDetails: {
        email: '',
        userRole: '',
    },
    activeSideBarIconReducer: {
        activeSideBarIcon: CONTAINER_CONST.OTA_DETAILS_ACTIVE
    },
    activateUpdateSystemButtonReducer: {
        activeUpdateSystemButton: false
    },
    showSystemUpdateFullScreenModalReducer: {
        showSystemUpdateModal: false
    }, 
    showNotificationReducer: {
        showNotification: false
    }, 
    showSystemInactiveFullScreenModalReducer: {
        showSystemInactiveModal: false
    },
    inactiveSystemVersionButtonReducer: {
        inactiveSystemVersionButton: false
    }, 
    showUploadSystemVersionFullScreenModalReducer: {
        showUploadSystemVersionModal: false
    }, 
    selectMachineToUpdateVersionReducer: {
        selectedMachineToUpdateVersion: []
    }, 
    updateMachineVersionNotificationReducer: {
        showMachineVersionNotification: false
    },
    uploadSystemVersionNotificationReducer: {
        showUploadSystemVersionNotification: false
    }
}

export default initialState;