import React from 'react';
import Table from 'rc-table';
import './ReactTable.css';
import 'rc-table/assets/index.css';

const ReactTable = (props) => {
  return (
    <div className='react-table'>
      <Table columns={props.columns} data={props.data} onRow={props.config} rowKey={props.rowKey} className='sticky-header'/>
    </div>
  )
}

export default ReactTable;        