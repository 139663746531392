
export const getLocalStorageCustom = (name) => {

    if (name) return localStorage.getItem(name);
    return null;
}

export const setLocalStorageCustom = (name, value) => {

    if (name && value) localStorage.setItem(name, value);
}

export const setCookieCustom = (name, value, expires) => {
    document.cookie = `${name}=${value}; expires=${expires || ''}; Path=/;`;
}

export const getCookieCustom = (name) => {
    try {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    } catch (err) {
        return null;
    }
}

export const deleteCookieCustom = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
