import React, { useEffect, useState, useRef } from 'react'
import './SubHeader.css'
import { setActiveSystemUpdateIcon, setNotificationVisibility, setSystemUpdateFullScreenModal } from '../../modules/actions/action';
import { connect } from 'react-redux';
import MessageNotification from '../MessageNotification/MessageNotification';

const SubHeader = (props) => {
    const {handleSearchChange, searchQuery} = props;
    const searchInputRef = useRef(null);
    // console.log("Notification", props.showNotification);
    const handleUpdateButton = () => {
        // If any row is selected, update button will be clickable otherwise it will be disabled
        props.openModal();
    };

    useEffect(() => {
        // Event listener for Command + K
        const handleKeyDown = (event) => {
            if (event.metaKey && event.key === 'k') {
            event.preventDefault();
            if (searchInputRef.current) {
                searchInputRef.current.focus();
            }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            props.closeNotification();
        }, 8000);
    }, [props.showNotification, props]);

    // console.log("Update Button", props.updateSystemIcon)
    return (
        <div className='stage-header'>
            <div className='stage-header-content'>
                <div>
                    <h2>V730i</h2>
                </div>
                <div className='stage-header-buttons'>
                    <input
                        type='text'
                        placeholder='Quick Search          ⌘ K'
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className='search-input'
                        ref={searchInputRef}
                    />
                    <button className={`${props.updateSystemIcon === true ? 'stage-header-btn' : 'disabledUpdateIcon'}`} disabled={!props.updateSystemIcon} onClick={handleUpdateButton}>Update</button>
                </div>
            </div>
            {props.showNotification && <MessageNotification />}
        </div>
    )
};


const mapStateToProps = state => {
    return {
        updateSystemIcon: state.updateSystemIcon.activeUpdateSystemButton,
        showNotification: state.showNotification.showNotificatin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateSystemUpdateButton: () => { dispatch(setActiveSystemUpdateIcon(true)) },
        deactivateSystemUpdateButton: () => { dispatch(setActiveSystemUpdateIcon(false)) },
        openModal: () => { dispatch(setSystemUpdateFullScreenModal(true)) },
        closeModal: () => { dispatch(setSystemUpdateFullScreenModal(false)) },
        closeNotification: () => { dispatch(setNotificationVisibility(false)) },
        openNotification: () => { dispatch(setNotificationVisibility(true)) }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);