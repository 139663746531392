import { Types } from '../actions/actionTypes';
import  initialState  from '../store/initialState'

export const userDetailsReducer = (state = initialState.userDetails, action) => {
    switch (action.type) {
        case Types.LOGIN_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    };
};