import React, { useEffect, useState, useRef } from 'react'
import './DemoMachine.css'
import ReactTable from '../../components/ReactTable/ReactTable';
import {getDemoMachinesList} from '../../helper/DemoMachinesListHelper';
import { AssignHospital } from '../../helper/RequestCallbackHelper';
import { connect } from 'react-redux';
import moment from 'moment';
import { TIMELINE_CONST } from '../../common/constant/common-constants';
import { ROLE } from '../../enum/role';

const DemoMachine = (props) => {
  const [dataContainer, setDataContainer] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef(null);
  const [runTimeQuery, setRunTimeQuery] = useState(10);
  const daysInputRef = useRef(null);

  useEffect(() => {
    handleDemoMachine(runTimeQuery);
    // Event listener for Command + K
    const handleKeyDown = (event) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleDemoMachine = (time) => {
    getDemoMachinesList(time).then((res) => {
      let data = res.data;
      setDataContainer(data);
    })
    .catch((err) => {
      console.log(err);
    })
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleTimeChange = (e) => {
    setRunTimeQuery(e.target.value)
    if (e.target.value){
      handleDemoMachine(e.target.value)
    }
  }

  const openCMS = (ventilatorUDI) => {
    let email = props.email;
    if (email) {
      AssignHospital({
        ventilatorUDI: ventilatorUDI,
        email: email
      }).then(res=>{
        if (res === 'Hospital ID updated successfully.'){
          window.open(process.env.REACT_APP_CMS_APP_URL, '_blank');
        }else {
          alert(res);
        }
      })
    }
  };

  const getTableColumn = () => {
    const columns = [
      {
        title: 'Serial Number',
        dataIndex: 'SerialNo',
        key: 'SerialNo',
        className: 'machine_serial_number_id_class header_column text-align-left',
        render: (value, row) => (
          <div className='clickable' onClick={()=>openCMS(value)}>
            {value.split('-').filter(x=>x.includes('1G'))}
          </div>
        )
      },
      {
        title: 'Current Version',
        dataIndex: 'osVersion',
        key: 'osVersion',
        className: 'machine_serial_number_id_class header_column',
        render: (value, row) => {
          return(
            <>
              OS: {row.osVersion} <br />
              SF: {row.softwareVersion}
            </>
          )
        }
      },
      {
        title: 'Location',
        dataIndex: 'Location',
        key: 'Location',
        className: 'current_version_class header_column location',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <>{value}</>
          }
        }
      },
      {
        title: `Run Time (${runTimeQuery} Days)`,
        dataIndex: 'MachineUpTimeInLastDay',
        key: 'MachineUpTimeInLastDay',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: `Total Run Time`,
        dataIndex: 'MachineUpTime',
        key: 'MachineUpTime',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: 'Current Status',
        dataIndex: 'status',
        key: 'status',
        className: 'update_time_class header_column',
        render: (value, row) => {
          if (!value) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: 'Owner',
        dataIndex: 'Owner',
        key: 'Owner',
        id: 'ots_status_id',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value === null) {
            return <span>-</span>
          } else {
            return <span>{value}</span>
          }
        }
      },
      {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        className: 'update_date_class header_column',
        render: (value, row) => {
          if (value == null) {
            return <span>-</span>
          } else {
            return <span>{moment(value).format(TIMELINE_CONST.DATE_FORMAT) + ' ' + moment(value).format(TIMELINE_CONST.TIME_FORMAT)}</span>
          }
        }
      },
    ];

    return columns;
  };

  const getTableData = () => {
    let filteredData = [];

    if (Array.isArray(dataContainer)) {
      filteredData = dataContainer.filter(item =>
        item.SerialNo?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
    }
    return filteredData;
  };

  if (!(props.userRole === ROLE['ADMIN'] || props.userRole === ROLE['SERVICE'] || props.userRole === ROLE['PRODUCTION'])) {
		return (
			  <div>
            <h1>Unauthorized Access</h1>
            <p>You do not have the necessary permissions to access this page.</p>
        </div>
		)
	}

  return (
    <div style={{position:"relative"}}>
      <div className='ots-details-container'>
        <div className='system-detail-sub-header-container'>
          <div className='system-detail-sub-header'>
            <div className='system-detail-sub-header-heading'>
              Demo Machines
            </div>
            <div className='filterWraper'>
              <input
                type='text'
                placeholder='Quick Search        ⌘ K'
                value={searchQuery}
                onChange={handleSearchChange}
                className='search-input'
                ref={searchInputRef}
              />
              <div className='runtime'>
                Run time:
                <input
                  type='number'
                  ref={daysInputRef}
                  onWheel={() => {daysInputRef.current.blur()}}
                  placeholder='Days'
                  value={runTimeQuery}
                  min='1'
                  onChange={handleTimeChange}
                  className='time-input'
                />
                days
              </div>
            </div>
          </div>
        </div>
        <ReactTable columns={getTableColumn()} data={getTableData()} rowKey="mahcineUDI" className="react-table" />
      </div>
    </div>
  )
};


const mapStateToProps = (state) => {
  const email = state.userDetails?.email
  const userRole = state.userDetails.userRole
  return {
    email: email,
    userRole: userRole,
  };
}

export default connect(mapStateToProps, null)(DemoMachine)
