// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import { CONTAINER_CONST } from "../../common/constant/common-constants";
import  initialState  from '../store/initialState'

// let initialState = {
//     activeSideBarIcon: CONTAINER_CONST.OTA_DETAILS_ACTIVE
// }

export const activeIconReducer = (state = initialState.activeSideBarIconReducer, action) => {
    switch (action.type) {
        case Types.CHANGE_SIDEBAR_ACTIVE_ICON:
            return { ...state, activeSideBarIcon: action.payload };
        default:
            return state;
    };
};