
export const covertDateString = (observedTime) => observedTime?.slice(0,4) + '-' + observedTime?.slice(4,6) + '-' + observedTime?.slice(6,8);

export const covertTimeString = (observedTime) => {
    return observedTime?.slice(8,10) > 12 ? 
            observedTime?.slice(8,10) - 12 +  ':' + observedTime?.slice(10,12) + ':' + observedTime?.slice(12,14) + 'PM' 
        : observedTime?.slice(8,10) == 12 ? 
            observedTime?.slice(8,10) + ':' + observedTime?.slice(10,12) + ':' + observedTime?.slice(12,14) + 'PM'
        : 
            observedTime?.slice(8,10) + ':' + observedTime?.slice(10,12) + ':' + observedTime?.slice(12,14) + 'AM'
}

export const getQueryParams = (url) => {
    const params = new URL(url).searchParams;
    let queryParams = {};
    for (let [key, value] of params) {
        queryParams[key] = value;
    }
    return queryParams;
}