export const PATIENT_TYPE = {
    neonatal: 'NEONATAL',
    pediatric: 'PEDIATRIC',
    adult: 'ADULT',
}

export const ALARM_PRIORITY = {
    high: 1,
    medium: 2,
    low: 3,
}

export const SET_PARAMETERS = {
    100501: {
        key: 'fio2',
        TITLE_START: 'FiO',
        TITLE_SUB_SCRIPT: '2',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100502: {
        key: 'peep',
        TITLE_START: 'PEEP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100503: {
        key: 'rr',
        TITLE_START: 'RR',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'b/min',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100504: {
        key: 'pc_above_peep',
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'insp',
        TITLE_END: ' abv PEEP',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100506: {
        key: 'i_e',
        TITLE_START: 'I:E',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: '',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100507: {
        key: 't_rise',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'rise',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100508: {
        key: 'trigger_senstivity',
        TITLE_START: 'Trigger Sensitivity',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'l/min',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100509: {
        key: 'ps_above_peep',
        TITLE_START: 'PS',
        TITLE_SUB_SCRIPT: 'abv',
        TITLE_END: ' PEEP',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100510: {
        key: 'end_insp',
        TITLE_START: 'End Insp',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100511: {
        key: 'tidal_volume',
        TITLE_START: 'VT',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: 'ml',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100512: {
        key: 't_pause',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'pause',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100513: {
        key: 'slope_value',
        TITLE_START: 'Flow Slope',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100514: {
        key: 'sync_window',
        TITLE_START: 'Sync',
        TITLE_SUB_SCRIPT: 'window',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100515: {
        key: 'apnea_time',
        TITLE_START: 'Apnea Time',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100516: {
        key: 'plow',
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'low',
        TITLE_END: ' ',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100517: {
        key: 'phigh',
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'high',
        TITLE_END: ' ',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100518: {
        key: 'tlow',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'low',
        TITLE_END: ' ',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100519: {
        key: 'thigh',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'high',
        TITLE_END: ' ',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100520: {
        key: 'ps_above_phigh',
        TITLE_START: 'PS abv P',
        TITLE_SUB_SCRIPT: 'high',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100521: {
        key: 'ps_above_plow',
        TITLE_START: 'PS abv P',
        TITLE_SUB_SCRIPT: 'low',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100522: {
        key: 'flow_rate',
        TITLE_START: 'Flow Rate',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'l/min',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100523: {
        key: 'flow_pattern',
        TITLE_START: 'Flow Pattern',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: '',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100524: {
        key: 'cpap_peep',
        TITLE_START: 'PEEP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100525: {
        key: 'cpap_end_insp',
        TITLE_START: 'End Insp',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100526: {
        key: 'cpap_t_rise',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'rise',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    100527: {
        TITLE_START: 'EPAP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100528: {
        TITLE_START: 'IPAP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    100529: {
        TITLE_START: 'Ti',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
}

export const OBSERVED_PARAMETERS = {
   104001: {
        key: 'fio2',
        TITLE_START: 'FiO',
        TITLE_SUB_SCRIPT: '2',
        TITLE_END: '',
        UNIT_START:"%",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104002: {
        key: 'rr',
        TITLE_START: 'RR',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"b/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104003: {
        key: 'i',
        TITLE_START: 'I',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        
    },
   104004: {
        key: 'e',
        TITLE_START: 'E',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ''
    },
   104005: {
        key: 'pplat',
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'plat',
        TITLE_END: '',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104006: {
        key: 'pip',
        TITLE_START: 'PiP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
   104007: {
        key: 'peep',
        TITLE_START: 'PEEP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
   104008: {
        key: 'vti',
        TITLE_START: 'VT',
        TITLE_SUB_SCRIPT: 'i',
        TITLE_END: '',
        UNIT_START:"ml",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104009: {
        key: 'vte',
        TITLE_START: 'VT',
        TITLE_SUB_SCRIPT: 'e',
        TITLE_END: '',
        UNIT_START:"ml",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104010: {
        key: 'vts',
        TITLE_START: 'VT',
        TITLE_SUB_SCRIPT: 'sp',
        TITLE_END: '',
        UNIT_START:"ml",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104011: {
        key: 'flow_neg',
        TITLE_START: '',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"ml",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104012: {
        key: 'flowee',
        TITLE_START: 'Flow',
        TITLE_SUB_SCRIPT: 'ee',
        TITLE_END: '',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104013: {
        key: 'c_stat',
        TITLE_START: 'C',
        TITLE_SUB_SCRIPT: 'stat',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104014: {
        key: 'r_static',
        TITLE_START: 'R',
        TITLE_SUB_SCRIPT: 'stat',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104015: {
        key: 'p_0_1',
        TITLE_START: 'P0.1',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104016: {
        key: 'cdyn',
        TITLE_START: 'C',
        TITLE_SUB_SCRIPT: 'dyn',
        TITLE_END: '',
        UNIT_START:'ml/cmH',
        UNIT_SUB_SCRIPT:'2',
        UNIT_END:'O'
    },
   104017: {
        key: 'pdrive',
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'drive',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104018: {
        key: 'para_cpap',
        TITLE_START: '',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104019: {
        key: 'rrs',
        TITLE_START: 'RR',
        TITLE_SUB_SCRIPT: 'sp',
        TITLE_END: '',
        UNIT_START:'b/min',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104020: {
        key: 'etco2',
        TITLE_START: 'EtCO',
        TITLE_SUB_SCRIPT: '2',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104021: {
        key: 'spo2',
        TITLE_START: 'SpO',
        TITLE_SUB_SCRIPT: '2',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104022: {
        key: 'pwob',
        TITLE_START: 'WOB',
        TITLE_SUB_SCRIPT: 'pat',
        TITLE_END: '',
        UNIT_START:'Joules',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104023: {
        key: 'flow_rate',
        TITLE_START: 'Flow Rate',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'l/min',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104024: {
        key: 'pulse_rate',
        TITLE_START: 'PR',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104025: {
        key: 'perfusion_index',
        TITLE_START: 'PI',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104026: {
        key: 'pleth',
        TITLE_START: 'Pleth',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104027: {
        key: 'humidifier_level_temp1',
        TITLE_START: '',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104028: {
        key: 'humidifier_level_temp2',
        TITLE_START: '',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104029: {
        key: 'battery_info',
        TITLE_START: '',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104030: {
        key: 'status_register',
        TITLE_START: '',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104031: {
        key: 'cal_param_mve',
        TITLE_START: 'MV',
        TITLE_SUB_SCRIPT: 'e',
        TITLE_END: '',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104032: {
        key: 'cal_param_mvi',
        TITLE_START: 'MV',
        TITLE_SUB_SCRIPT: 'i',
        TITLE_END: '',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104033: {
        key: 'cal_param_spmve',
        TITLE_START: 'MV',
        TITLE_SUB_SCRIPT: 'e sp',
        TITLE_END: '',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    i_e: {
        key: 'i_e',
        TITLE_START: 'I:E',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:'',
        UNIT_SUB_SCRIPT:'',
        UNIT_END:''
    },
   104034:{
        key: 'cal_param_p_mean',
        TITLE_START:"P",
        TITLE_SUB_SCRIPT:"mean",
        TITLE_END:'',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:'',
    },
   104035:{
        key: 'cal_param_leakage_vt',
        TITLE_START: 'Leakage',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"%",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104036:{
        key: 'cal_param_ti',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'e',
        TITLE_END: '',
        UNIT_START:"s",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    104037:{
        key: 'cal_param_te',
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'i',
        TITLE_END: '',
        UNIT_START:"s",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
   104038: {
        key: 'rsbi',
        TITLE_START: 'RSBI',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"b/min/l",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    }
}

export const MANEUVERS = {
    111001: {
        key : 'Inspiratory_hold',
        TITLE_START: 'Inspiratory Hold',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111002: {
        key : 'expiratory_hold',
        TITLE_START: 'Expiratory Hold',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111003: {
        key : 'o2_boost',
        TITLE_START: 'O',
        TITLE_SUB_SCRIPT: '2',
        TITLE_END: ' Boost',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111004: {
        key : 'suction_support',
        TITLE_START: 'Suction Support',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111005: {
        key : 'nif',
        TITLE_START: 'NIF',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111006: {
        key : 'p01',
        TITLE_START: 'P0.1',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111007: {
        key : 'manual_breath',
        TITLE_START: 'Manual Breath',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111008: {
        key : 'aerogen_nebulizer',
        TITLE_START: 'Ultrasonic Nebulizer',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    111009: {
        key : 'smart_humidifier',
        TITLE_START: 'Smart Humidifier',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
}

export const TEMP_PARAMETERS={
    pip:{
        id:1,
        TITLE_START:"PIP",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    ipap:{
        id:1,
        TITLE_START:"IPAP",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    ppleatu:{
        id:2,
        TITLE_START:"P",
        TITLE_SUB_SCRIPT:"plat",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    peep:{
        id:3,
        TITLE_START:"PEEP",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    epap:{
        id:3,
        TITLE_START:"EPAP",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    i_e:{
        id:4,
        TITLE_START:"I:E",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    leakagevt:{
        id:5,
        TITLE_START:"Leakage",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"%",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    vti:{
        id:6,
        TITLE_START:"VT",
        TITLE_SUB_SCRIPT:"i",
        TITLE_END:'',
        UNIT_START:"ml",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    vte:{
        id:7,
        TITLE_START:"VT",
        TITLE_SUB_SCRIPT:"e",
        TITLE_END:'',
        UNIT_START:"ml",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    rr:{
        id:8,
        TITLE_START:"RR",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"b/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    fio2:{
        id:9,
        TITLE_START:"FiO",
        TITLE_SUB_SCRIPT:"2",
        TITLE_END:'',
        UNIT_START:"%",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    mve:{
        id:10,
        TITLE_START:"MV",
        TITLE_SUB_SCRIPT:"e",
        TITLE_END:'',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    mvi:{
        id:11,
        TITLE_START:"MV",
        TITLE_SUB_SCRIPT:"i",
        TITLE_END:'',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    mvspont:{
        id:12,
        TITLE_START:"MV",
        TITLE_SUB_SCRIPT:"e",
        TITLE_END:'sp',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    vtspont:{
        id:13,
        TITLE_START:"VT",
        TITLE_SUB_SCRIPT:"e",
        TITLE_END:'sp',
        UNIT_START:"l",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    flowrate:{
        id:14,
        TITLE_START:"Flow Rate",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    cstate:{
        id:15,
        TITLE_START:"C",
        TITLE_SUB_SCRIPT:"stat",
        TITLE_END:'',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    cdyn:{
        id:16,
        TITLE_START:"C",
        TITLE_SUB_SCRIPT:"dyn",
        TITLE_END:'',
        UNIT_START:"ml/cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    }, 
    pdrive:{
        id:17,
        TITLE_START:"P",
        TITLE_SUB_SCRIPT:"drive",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    rrspont:{
        id:18,
        TITLE_START:"RR",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'sp',
        UNIT_START:"b/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    etco2:{
        id:19,
        TITLE_START:"EtCO",
        TITLE_SUB_SCRIPT:"2",
        TITLE_END:'',
        UNIT_START:"%",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    spo2:{
        id:20,
        TITLE_START:"SpO",
        TITLE_SUB_SCRIPT:"2",
        TITLE_END:'',
        UNIT_START:"%",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    pwob:{
        id:21,
        TITLE_START:"WOBpat",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    p01:{
        id:23,
        TITLE_START:"P0.1",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    pmean:{
        id:24,
        TITLE_START:"P",
        TITLE_SUB_SCRIPT:"mean",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O'
    },
    ti:{
        id:25,
        TITLE_START:"T",
        TITLE_SUB_SCRIPT:"i",
        TITLE_END:'',
        UNIT_START:"s",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    ri:{
        id:26,
        TITLE_START:"R",
        TITLE_SUB_SCRIPT:"i",
        TITLE_END:'',
        UNIT_START:"b/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    te:{
        id:27,
        TITLE_START:"T",
        TITLE_SUB_SCRIPT:"e",
        TITLE_END:'',
        UNIT_START:"s",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    flowee:{
        id:28,
        TITLE_START:"Flow",
        TITLE_SUB_SCRIPT:"ee",
        TITLE_END:'',
        UNIT_START:"l/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    pluserate:{
        id:29,
        TITLE_START:"PR",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"b/min",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
     perfusionindex:{
        id:30,
        TITLE_START:"PI",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    pleth:{
        id:31,
        TITLE_START:"Pleth",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    rsbi:{
        id:32,
        TITLE_START:"RSBI",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"b/min/l",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    elastance:{
        id:33,
        TITLE_START:"E",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"cmH",
        UNIT_SUB_SCRIPT:"2",
        UNIT_END:'O/l'
    },
    tvpbw:{
        id:34,
        TITLE_START:"TV:PBW",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"ml/kg",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
    tvbw:{
        id:35,
        TITLE_START:"TV:BW",
        TITLE_SUB_SCRIPT:"",
        TITLE_END:'',
        UNIT_START:"ml/kg",
        UNIT_SUB_SCRIPT:"",
        UNIT_END:''
    },
}


export const TEMP_SET_PARAMETERS = {
    fio2: {
        TITLE_START: 'FiO',
        TITLE_SUB_SCRIPT: '2',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    peep: {
        TITLE_START: 'PEEP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    rr: {
        TITLE_START: 'RR',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'b/min',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    pc_above_peep: {
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'insp',
        TITLE_END: ' abv PEEP',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    i_e: {
        TITLE_START: 'I:E',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: '',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    t_rise: {
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'rise',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    ps_above_peep: {
        TITLE_START: 'PS abv',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' PEEP',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    end_insp: {
        TITLE_START: 'End Insp',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    tidal_volume: {
        TITLE_START: 'VT',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: 'ml',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    t_pause: {
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'pause',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    slope_value: {
        TITLE_START: 'Slope',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    sync_window: {
        TITLE_START: 'Sync window',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    apnea_time: {
        TITLE_START: 'Apnea Time',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: ' ',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    plow: {
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'low',
        TITLE_END: ' ',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    phigh: {
        TITLE_START: 'P',
        TITLE_SUB_SCRIPT: 'high',
        TITLE_END: ' ',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    tlow: {
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'low',
        TITLE_END: ' ',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    thigh: {
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'high',
        TITLE_END: ' ',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    ps_above_phigh: {
        TITLE_START: 'PS abv P',
        TITLE_SUB_SCRIPT: 'High',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    ps_above_plow: {
        TITLE_START: 'PS abv P',
        TITLE_SUB_SCRIPT: 'Low',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    flow_rate: {
        TITLE_START: 'Flow Rate',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'l/min',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    flow_pattern: {
        TITLE_START: 'Flow Pattern',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: '',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    cpap_peep: {
        TITLE_START: 'PEEP',
        TITLE_SUB_SCRIPT: '/',
        TITLE_END: 'CPAP',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    cpap_end_insp: {
        TITLE_START: 'End Insp',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    cpap_t_rise: {
        TITLE_START: 'T',
        TITLE_SUB_SCRIPT: 'rise',
        TITLE_END: '',
        UNIT_START: '%',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    epap: {
        TITLE_START: 'EPAP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    ipap: {
        TITLE_START: 'IPAP',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'cmH',
        UNIT_SUB_SCRIPT: '2',
        UNIT_END: 'O'
    },
    t_insp: {
        TITLE_START: 'Ti',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 's',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    },
    trigger_senstivity: {
        TITLE_START: 'Trigger Sensitivity',
        TITLE_SUB_SCRIPT: '',
        TITLE_END: '',
        UNIT_START: 'l/min',
        UNIT_SUB_SCRIPT: '',
        UNIT_END: ''
    }
}