// import initialState from '../store/initialState';
import { Types } from '../actions/actionTypes';
import { CONTAINER_CONST } from "../../common/constant/common-constants";
import  initialState  from '../store/initialState'


export const showMachineUpdateNotificationReducer = (state = initialState.updateMachineVersionNotificationReducer, action) => {
    switch (action.type) {
        case Types.SHOW_UPDATE_MACHINE_VERSION_NOTIFICATION:
            return { ...state, showMachineVersionNotification: action.payload };
        default:
            return state;
    };
};